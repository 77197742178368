import React, { useState, useEffect, useContext } from "react";
import Script from 'react-load-script';
import Button from '@mui/material/Button';
import Axios from 'axios';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { UserContext } from "../../context/AuthContext";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// let OmiseCard
export default function CreditCard({amount,duration,plan}) {
  const {user, authenticated} = useContext(UserContext)
  const sqlDatetime = (inputDate, daysToAdd = 0) => {
    const date = new Date(inputDate);
    date.setDate(date.getDate() + daysToAdd);
    const dateWithOffest = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    return dateWithOffest
      .toISOString()
      .slice(0, 19)
      .replace('T', ' ');
  };
    // const handleLoadScript = () => {
    //     OmiseCard = window.OmiseCard
    //     OmiseCard.configure({
    //         publicKey: 'pkey_test_5tcziynopmane75t7s2',
    //         currency: 'THB',
    //         image: '',
    //         frameLabel: 'Kittikun Corp.',
    //         submitLabel: 'PAY NOW',
    //         buttonLabel: 'Pay with Omise'
    //     });
    // }

    // const creditCardConfigure = () => {
    //     OmiseCard.configure({
    //         defaultPaymentMethod: 'credit_card',
    //         otherPaymentMethods: ['internet_banking', 'bill_payment_tesco_lotus', 'rabbit_linepay', 'truemoney']
    //     });
    //     OmiseCard.configureButton("#credit-card");
    //     OmiseCard.attach();
    // }

    // const omiseCardHandler = () => {
    //     OmiseCard.open({
    //         amount: amount*100,
    //         onCreateTokenSuccess: (token) => {
    //             Axios.post('https://apicourse.jknowledgetutor.com/checkout-credit-card', {
    //                 email: payerEmail,
    //                 name: payerName,
    //                 amount: amount*100,
    //                 token: token,
    //                 headers: {
    //                     "Content-Type": "application/json"
    //                 }
    //             }).then((res) => {
    //                 console.log(res.data);

    //             })
    //         },
    //         onFormClosed: () => {

    //         },
    //     })
    // }

    const [loginStatus, setLoginStatus] = useState('');
    const [statusColor, setStatusColor] = useState();
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [open, setOpen] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        if (statusColor === 'success'){
            // window.location = '/profile'
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        // console.log('user',user);
        // creditCardConfigure();
        // omiseCardHandler();
        // console.log('amount',amount);
        // console.log('duration',duration);
        // console.log('plan',plan);
        // console.log('Time',sqlDatetime(new Date()));
        // console.log('Time',sqlDatetime(new Date(), duration));
        const accessToken = localStorage.getItem("accessToken");
        Axios.post('https://apicourse.jknowledgetutor.com/create-subscript', {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
              },
            user_id: user.user_id,
            plan_id: plan,
            start_date: sqlDatetime(new Date()),
            end_date: sqlDatetime(new Date(), duration)
        }).then((res) => {
            console.log(res);
            setLoginStatus('Subscript Success Plan:' + duration + 'Days')
            setStatusColor('success')
            setOpen(true)
        })
    }

    return (
        <>  
            <Snackbar open={open} autoHideDuration={1200} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={statusColor} sx={{ width: '100%', mt: '2.5rem'}}>
                    {loginStatus}
                </Alert>
            </Snackbar>

            <Stack sx={{ textAlign: 'center', p: 5 }} >
                <Box>
                    {/* <Script url="https://cdn.omise.co/omise.js" onLoad={handleLoadScript} /> */}
                </Box>
                <Box component='form'>
                    <Button variant="contained" id="credit-card" type="button" onClick={handleClick}>ชำระเงิน</Button>
                </Box>
            </Stack>
        </>
    )
}

import React, { useState, useEffect } from 'react'
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArticleIcon from '@mui/icons-material/Article';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import BallotIcon from '@mui/icons-material/Ballot';
import { Box } from '@mui/material';
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";


export default function SidebarList({ lesson, coursesSlug, progress}) {
    // console.log('progress',progress);
    const [open, setOpen] = React.useState(true);
    const handleClick = () => {
        setOpen(!open);
    };

    const getProgress = (id) => {
            // let time_progress = 0
            // progress?.map((values, key) => {
            //     if (values.lesson_id == id) {
            //         const percentProgress = parseInt(values.time_progress/(lesson_video_length.split(":")[0]*60)*100);
            //         if (percentProgress > 100) {
            //             time_progress = 100
            //         }else{
            //             time_progress = percentProgress
            //         }
            //     }
            //   });      
            // return time_progress;  
            let time_progress = 0
            progress?.map((values, key) => {
                if (values.lesson_id == id) {
                    const percentProgress = parseInt(values.time_progress);
                    if (percentProgress > 100) {
                        time_progress = 100
                    }else{
                        time_progress = percentProgress
                    }
                }
              });      
            return time_progress;  
    };
    
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
            height: 5,
            borderRadius: 3,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor:
                theme.palette.grey[theme.palette.mode === "light" ? 400 : 800],
            },
            [`& .${linearProgressClasses.bar}`]: {
              borderRadius: 5,
              backgroundColor: theme.palette.mode === "light" ? "#a0d64b" : "#aaa",
            },
          }));

    return (
        <List
            sx={{ width: '100%', maxWidth: 1000, backgroundColor:'white',borderRadius:1}}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">

                </ListSubheader>
            }
        >
            <ListItemButton href={`/courses/${coursesSlug}`} >
                <ListItemIcon >
                    <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="บทนำ " />
            </ListItemButton>

            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    <BallotIcon />
                </ListItemIcon>
                <ListItemText primary="เนื้อหา" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <List component="div" disablePadding>
                        {lesson?.map((val, key) => {
                            return (
                                <>
                                <ListItemButton sx={{ pt: 1, pl:4 ,alignItems:"flex-end" }}
                                    href={`/courses/${coursesSlug}/${val.lesson_slug}`}
                                    key={key}>
                                    <ListItemIcon>
                                        <SmartDisplayIcon />
                                    </ListItemIcon> 
                                    <ListItemText primary={`บทที่ ${key + 1} ${val.lesson_name} `} />{/* ${getProgress(val.lesson_id,val.lesson_video_length)} % */}
                                    <Box>
                                    <ListItemText primary={`${val.lesson_video_length} นาที`} />
                                    </Box>                   
                                </ListItemButton>
                                <BorderLinearProgress
                                variant="determinate"
                                style={{ display: "block" }}
                                value={getProgress(val.lesson_id,val.lesson_video_length)}
                                /> 
                                </>
                            )
                        })}
                    </List>
                </List>
            </Collapse>

            <ListItemButton>
                <ListItemIcon>
                    <BorderColorIcon />
                </ListItemIcon>
                <ListItemText primary="ข้อสอบ" />
            </ListItemButton>

        </List>

    );
}

import React, { useContext, useEffect, useState } from "react";
import { TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Axios from "axios";

function ProfileSetting({ user }) {
  const [userMeta, setUserMeta] = useState();
  const [newFname, setNewFname] = useState();
  const [newLname, setNewLname] = useState();
  const [newEmail, setNewEmail] = useState();

  useEffect(() => {
    try {
      if (user) {
        const accessToken = localStorage.getItem("accessToken");
        Axios.post(`https://apicourse.jknowledgetutor.com/getAUserMeta/${user.user_id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }).then((res) => {
          setUserMeta(res.data);
          setNewFname(user.fname);
          setNewLname(user.lname);
          setNewEmail(user.email);
        });
      }
    } catch (error) {}
  }, [user]);

  const updateAUser = () => {
    const accessToken = localStorage.getItem("accessToken");
    Axios.put("https://apicourse.jknowledgetutor.com/updateAUser", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      fname: newFname,
      lname: newLname,
      email: newEmail,
      user_id: user.user_id,
    }).then((res) => {
      console.log("Response:", res.data);
      window.location.reload();
    });
  };

  // console.log(userMeta);

  return (
    <>
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Box m={2}>
          <Typography
            sx={{
              display: "inline",
              fontSize: "1.8rem",
              borderBottom: "4px solid #a0d64b",
            }}
          >
            ประวัติส่วนตัว
          </Typography>
        </Box>

        <Box
          p={2}
          m={2}
          sx={{ borderRadius: 3, bgcolor: "white", boxShadow: 1 }}
        >
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Stack direction="row" alignItems="center" justifyContent="left">
                <Grid item xs={4}>
                  <Typography sx={{ fontSize: "1rem" }}>
                    ชื่อ - นามสกุล
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography sx={{ fontSize: "1rem" }}>
                    {user.fname} {user.lname}
                  </Typography>
                </Grid>
              </Stack>
              {userMeta?.map((val, key) =>
                val.user_meta_key === "โรงเรียน" ||
                val.user_meta_key === "จังหวัด" ||
                val.user_meta_key === "ระดับชั้น" ? (
                  <Grid item key={key}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Grid item xs={4}>
                        <Typography sx={{ fontSize: "1rem" }}>
                          {val.user_meta_key}
                        </Typography>
                      </Grid>
                      <Typography sx={{ fontSize: "1rem" }}>
                        {val.user_meta_value}
                      </Typography>
                    </Stack>
                  </Grid>
                ) : null
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      {userMeta ? (
        <Box sx={{ flexGrow: 1, mt: 2 }}>
          <Box m={2}>
            <Typography
              sx={{
                display: "inline",
                fontSize: "1.8rem",
                borderBottom: "4px solid #a0d64b",
              }}
            >
              แก้ไขโปรไฟล์
            </Typography>
          </Box>
          <Box
            p={2}
            m={2}
            sx={{ borderRadius: 3, bgcolor: "white", boxShadow: 1 }}
          >
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                >
                  <Grid item xs={4}>
                    <Typography>เบอร์โทรศัพท์</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      type="text"
                      size="small"
                      variant="outlined"
                      value={user.phone}
                      disabled
                    />
                  </Grid>
                </Stack>
              </Grid>

              <Grid item>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                >
                  <Grid item xs={4}>
                    <Typography>ชื่อ</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      type="text"
                      size="small"
                      variant="outlined"
                      value={newFname}
                      onChange={(e) => {
                        setNewFname(e.target.value);
                      }}
                    />
                  </Grid>
                </Stack>
              </Grid>

              <Grid item>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                >
                  <Grid item xs={4}>
                    <Typography>นามสกุล</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      type="text"
                      size="small"
                      variant="outlined"
                      value={newLname}
                      onChange={(e) => {
                        setNewLname(e.target.value);
                      }}
                    />
                  </Grid>
                </Stack>
              </Grid>

              <Grid item>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                >
                  <Grid item xs={4}>
                    <Typography>อีเมล</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      type="text"
                      size="small"
                      variant="outlined"
                      value={newEmail}
                      onChange={(e) => {
                        setNewEmail(e.target.value);
                      }}
                    />
                  </Grid>
                </Stack>
              </Grid>

              {userMeta.map((val, key) =>
                val.user_meta_key !== "ข้อตกลงและเงื่อนไข" ? (
                  <Grid item key={key}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Grid item xs={4}>
                        <Typography>{val.user_meta_key}</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          type="text"
                          size="small"
                          varaint="outlined"
                          value={val.user_meta_value}
                          disabled
                        />
                      </Grid>
                    </Stack>
                  </Grid>
                ) : null
              )}

              <Grid item>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                >
                  <Button
                    onClick={updateAUser}
                    variant="contained"
                    color="success"
                  >
                    บันทึก
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default ProfileSetting;

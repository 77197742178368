import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from "@mui/material/Stack";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import IconButton from "@mui/material/IconButton";


function Copyright() {
    return (
        <>
        <Typography variant="body2" color="text.secondary" sx={{ color: 'white', justifyContent: 'center' }}>
            {'Copyright © '}
            <Link color="inherit" href="https://jknowledgetutor.com/">
                Jknowledgetutor
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>

        </>
    );
}

export default function StickyFooter() {
    return (

        <Box
            component="footer"
            sx={{
                py: 2,
                px: 2,
                mt: 'auto',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? "#242424"
                        : "#242424",
            }}
        >
            <Container maxWidth="lg" sx={{ textAlign: 'center',color: 'white'}}>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Box sx={{ mx: { sx: 4, md: 5 } }}>
                <Stack direction="row">
                  <Box flex={9}>
                  <Typography fontSize={"1.1rem"} color="text.secondary" sx={{ color: 'white', justifyContent: 'left',textAlign:'left' }}>
                  บริษัท ดีพร้อมวิทยา จำกัด (J Knowledge Tutor)
                  </Typography>
                  <Typography  fontSize={"1rem"}  color="text.secondary" sx={{ color: 'white', justifyContent: 'left',textAlign:'left' }}>
                     สถาบันกวดวิชา เจ โนว์เลจ 328 หมู่ 19 ซอย หมู่บ้านสวนมอ ตำบลศิลา อำเภอเมืองขอนแก่น จังหวัดขอนแก่น 40000
                    </Typography>
                    <Typography  fontSize={"1rem"}  color="text.secondary" sx={{ color: 'white', justifyContent: 'left',textAlign:'left' }}>
                    E-mail: jknowledgetutor@hotmail.com
                    </Typography>
                   
                  </Box>
                  <Box flex={6}>
                  <IconButton
              size="small"
              href='https://www.facebook.com/Jknowledges'
            >
              <FacebookIcon  sx={{color:"white"}}  />
            </IconButton>
            <IconButton
              size="small"
              href='https://www.instagram.com/jknowledgetutor/'
            >
              <InstagramIcon  sx={{color:"white"}}  />
            </IconButton>
            <IconButton
              size="small"
              href='https://www.youtube.com/@JknowledgeTutor'
              // https://www.tiktok.com/@j_knowledge
            >
              <YouTubeIcon  sx={{color:"white"}}  />
            </IconButton>
            <IconButton
              size="small"
              href='https://www.tiktok.com/@j_knowledge'
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-tiktok" viewBox="0 0 16 16" color="white">
              <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z"/>
            </svg>
            </IconButton>
                  <Typography  fontSize={"1.1rem"} >
                    Jknowledge tutor ติว ติด พิชิตฝัน
                </Typography>
                <Copyright  fontSize={"1.1rem"} />
                  </Box>
                  </Stack>
                  </Box>
                  </Stack>
                
            </Container>
        </Box>

    );
}
import React, {useContext, useEffect, useState } from 'react'
import Cart from '../components/cart/Cart.js'
import Appbar from '../components/Appbar'
import Box from '@mui/material/Box'
import { UserContext } from "../context/AuthContext";
import Footer from "../components/Footer";

function CartPage() {
//   const [rowsData, setRowsData] = useState();
  const {user , authenticated} = useContext(UserContext);

  useEffect(() => {
    // setRowsData(JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('cart'), EncryptSecret).toString(CryptoJS.enc.Utf8)))
  }, [])


  if (user) {
    return (
      <>
        {authenticated ? <Appbar user={user}/> : <Appbar/>}
        <Box sx={{ marginBottom: '100px' }}>
          <Cart />
          {/* rowsData={rowsData} setRowsData={setRowsData} */}
        </Box>
      <Footer />

      </>
    )
  }
}

export default CartPage
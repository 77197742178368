import React, {useContext, useEffect, useState } from 'react'
import Appbar from '../components/Appbar'
import CoursesContent from '../components/CoursesList'
import { UserContext } from "../context/AuthContext";
import Footer from "../components/Footer";


function Courses() {
  const {user , authenticated} = useContext(UserContext);

  return (
    <>
     {authenticated ? <Appbar user={user}/> : <Appbar/>}
     {authenticated ? <CoursesContent user={user}/> : <CoursesContent/>}
      <Footer />
    </>

  )
}

export default Courses
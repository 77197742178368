import React, { useContext } from "react";
import Box from "@mui/material/Box";
import {
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import Divider from "@mui/material/Divider";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { UserContext } from "../../pages/Profile";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { theme } from "../../theme";
import LogoutIcon from "@mui/icons-material/Logout";
import Stack from "@mui/material/Stack";

function ProfileSidebar({ user, activeContent, setActiveContent }) {
  // const user = useContext(UserContext)
  const handleSetActiveContent = (activeContent) => {
    setActiveContent(activeContent);
    localStorage.setItem("ActiveContent", activeContent);
  };
  return (
    <>
      {user ? (
        <>
          <Box
            sx={{
              background: "#0e3746",
              borderRadius: 5,
              width: "100%",
              color: "#ffffff",
              
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              paddingX={3}
            >
              <Box marginX={1} flex={3}>
                <Box
                  sx={{
                    borderBottom:
                      activeContent === "profile-homepage"
                        ? "5px solid #a0d64b"
                        : null,
                    background:
                      activeContent === "profile-homepage" ? "#a0d64b25" : null,
                  }}
                  onClick={() => {
                    handleSetActiveContent("profile-homepage");
                  }}
                >
                  <Box>
                    <HomeIcon
                      sx={{
                        color:
                          activeContent === "profile-homepage"
                            ? "#a0d64b"
                            : "#ffffff",
                      }}
                    />
                  </Box>
                  <Typography sx={{ fontSize: "0.8rem" }}>หน้าแรก</Typography>
                </Box>
              </Box>
              <Box marginX={1} flex={3}>
                <Box
                  sx={{
                    borderBottom:
                      activeContent === "profile-mycourse"
                        ? "5px solid #a0d64b"
                        : null,
                    background:
                      activeContent === "profile-mycourse" ? "#a0d64b25" : null,
                  }}
                  onClick={() => {
                    handleSetActiveContent("profile-mycourse");
                  }}
                >
                  <ListItemIcon>
                    <AutoStoriesIcon
                      sx={{
                        color:
                          activeContent === "profile-mycourse"
                            ? "#a0d64b"
                            : "#ffffff",
                      }}
                    />
                  </ListItemIcon>
                  <Typography sx={{ fontSize: "0.8rem" }}>
                    คอร์สเรียนของฉัน
                  </Typography>
                </Box>
              </Box>
              <Box
                marginX={1} flex={3}
              >
                <Box
                  sx={{
                    borderBottom:
                      activeContent === "profile-payment"
                        ? "5px solid #a0d64b"
                        : null,
                    background:
                      activeContent === "profile-payment" ? "#a0d64b25" : null,
                  }}
                  onClick={() => {
                    handleSetActiveContent("profile-payment");
                  }}
                >
                  <ListItemIcon>
                    <CreditCardIcon
                      sx={{
                        color:
                          activeContent === "profile-payment"
                            ? "#a0d64b"
                            : "#ffffff",
                      }}
                    />
                  </ListItemIcon>
                  <Typography sx={{ fontSize: "0.8rem" }}>
                    การชำระเงิน
                  </Typography>
                </Box>
              </Box>
              <Box
                marginX={1} flex={3}
              >
                <Box
                  sx={{
                    borderBottom:
                      activeContent === "profile-setting"
                        ? "5px solid #a0d64b"
                        : null,
                    background:
                      activeContent === "profile-setting" ? "#a0d64b25" : null,
                  }}
                  onClick={() => {
                    handleSetActiveContent("profile-setting");
                  }}
                >
                  <Box>
                    <SettingsIcon
                      sx={{
                        color:
                          activeContent === "profile-setting"
                            ? "#a0d64b"
                            : "#ffffff",
                      }}
                    />
                  </Box>
                  <Typography sx={{ fontSize: "0.8rem" }}>
                    แก้ไขโปรไฟล์
                  </Typography>
                </Box>
              </Box>
            </Stack>

            {/* url: "/logout" */}
          </Box>
        </>
      ) : null}
    </>
  );
}

export default ProfileSidebar;

import Appbar from "../components/Appbar";
import Banner from '../components/Banner';
import Footer from "../components/Footer";
import Content from '../components/Content';
import CoursesListBar from "../components/CoursesListBar";
import { UserContext } from "../context/AuthContext";
import React, { useContext,useState} from "react";
// import LoadingScreen from 'react-loading-screen'

function Home() {
    // const [loading, setLoading] = useState(true);
    const { user, authenticated } = useContext(UserContext);  
    // setLoading(false)
    return (
        <>
            {/* <LoadingScreen loading={loading}
                bgColor="rgba(255,255,255,0.8)"
                spinnerColor="#478e6b"
                textColor="#676767"
                // logoSrc="http://localhost:3000/static/media/Jknowledge-Logo.18250765f4f4ae91679a.webp"
                text="" /> */}
            <Appbar user={user}/>
            <Banner />
            {authenticated ? <CoursesListBar user={user}/> : <CoursesListBar/>}
            <Content />
            <Footer />
        </>

    )
}


export default Home
import React, { useContext } from "react";
import Box from "@mui/material/Box";
import {
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import Divider from "@mui/material/Divider";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { UserContext } from "../../pages/Profile";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { theme } from '../../theme';
import LogoutIcon from '@mui/icons-material/Logout';

function ProfileSidebar({ user, activeContent, setActiveContent }) {
  // const user = useContext(UserContext)
  const handleSetActiveContent = (activeContent) => {
    setActiveContent(activeContent);
    localStorage.setItem("ActiveContent", activeContent);
  };
  const logOut =() => {
    localStorage.removeItem('accessToken')
    window.location = '/'
  }
  return (
    <>
      {user ? (
        <List sx={{ background: '#0e3746',borderRadius:10,color:'#ffffff',padding:5}}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mb: "20px"

            }}
          >
            <Box />
            <Tooltip>
              <IconButton sx={{ p: 0 ,margin:"auto"}}>
                <Avatar
                  alt={`${user.fname}`}
                  src="/static/images/avatar/1.jpg"
                  sx={{ minWidth: 90, minHeight: 90 }}
                >
                  {" "}
                  <Typography
                    sx={{ textTransform: "capitalize", fontSize: "2.5rem" }}
                  >
                    {user.fname.substring(0, 1)}
                  </Typography>
                </Avatar>
              </IconButton>
            </Tooltip>
            
          </Box>
          <Box>
          <Typography mx={2} sx={{ textTransform: "capitalize",fontSize: "1.5rem",minWidth:180 }}>
              {`${user.fname}  ${user.lname}`}
            </Typography>
          </Box>

          <ListItemButton
            sx={{
                borderBottom:
                activeContent === "profile-homepage"
                  ? "5px solid #a0d64b"
                  : null,
              background:
                activeContent === "profile-homepage" ? "#a0d64b25" : null,
            }}
            onClick={() => {
              handleSetActiveContent("profile-homepage");
            }}
          >
            <ListItemIcon>
              <HomeIcon
                sx={{
                  color:
                    activeContent === "profile-homepage" ? "#a0d64b" : "#ffffff",
                }}
              />
            </ListItemIcon>
            <ListItemText primary="หน้าแรก" />
          </ListItemButton>

          <ListItemButton
            sx={{
              borderBottom:
                activeContent === "profile-mycourse"
                  ? "5px solid #a0d64b"
                  : null,
              background:
                activeContent === "profile-mycourse" ? "#a0d64b25" : null,
            }}
            onClick={() => {
              handleSetActiveContent("profile-mycourse");
            }}
          >
            <ListItemIcon>
              <AutoStoriesIcon
                sx={{
                  color:
                    activeContent === "profile-mycourse" ? "#a0d64b" : "#ffffff",
                }}
              />
            </ListItemIcon>
            <ListItemText primary="คอร์สเรียนของฉัน" />
          </ListItemButton>

          <ListItemButton
            sx={{
                borderBottom:
                activeContent === "profile-payment"
                  ? "5px solid #a0d64b"
                  : null,
              background:
                activeContent === "profile-payment" ? "#a0d64b25" : null,
            }}
            onClick={() => {
              handleSetActiveContent("profile-payment");
            }}
          >
            <ListItemIcon>
              <CreditCardIcon
                sx={{
                  color: activeContent === "profile-payment" ? "#a0d64b" : "#ffffff",
                }}
              />
            </ListItemIcon>
            <ListItemText primary="การชำระเงิน" />
          </ListItemButton>

          <Divider />

          <ListItemButton
            sx={{
                borderBottom:
                activeContent === "profile-setting"
                  ? "5px solid #a0d64b"
                  : null,
              background:
                activeContent === "profile-setting" ? "#a0d64b25" : null,
            }}
            onClick={() => {
              handleSetActiveContent("profile-setting");
            }}
          >
            <ListItemIcon>
              <SettingsIcon
                sx={{
                  color: activeContent === "profile-setting" ? "#a0d64b" : "#ffffff",
                }}
              />
            </ListItemIcon>
            <ListItemText primary="แก้ไขโปรไฟล์" />
          </ListItemButton>
          <ListItemButton
            sx={{
                borderBottom:
                activeContent === ""
                  ? "5px solid #a0d64b"
                  : null,
              background:
                activeContent === "" ? "#a0d64b25" : null,
            }}
            onClick={() => {
              // handleSetActiveContent("profile-setting");
              logOut();
            }}
          >
            <ListItemIcon>
              <LogoutIcon
                sx={{
                  color: activeContent === "" ? "#a0d64b" : "#ffffff",
                }}
              />
            </ListItemIcon>
            <ListItemText primary="ออกจากระบบ" />
          </ListItemButton>

          {/* url: "/logout" */}
        </List>
      ) : null}
    </>
  );
}

export default ProfileSidebar;

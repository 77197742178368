import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Script from "react-load-script";
import Typography from "@mui/material/Typography";
// import SidebarList from './SidebarList';
import Tablofcontents from "./Tablofcontents";
import Axios from "axios";
import { FaAndroid } from "react-icons/fa";

function CourseLessonContent({
  user,
  coursesSlug,
  lesson,
  allLesson,
  progress,
  setProgress,
  setProgressCreateNew,
  progressCreateNew
}) {
  const [interval, setIntervalLoop] = useState(null);
  const [durations, setDuration] = useState();
  // const [progressLocal, setProgressLocal] = useState(progress);
  const [title, setTitle] = useState("");
  // const [progresstrigger, setprogresstrigger] = useState();

  const handleLoadScript = () => {
    const Vimeo = window.Vimeo;
    //
    const iframeXs = document.querySelector(".iFrameXs");
    const playerXs = new Vimeo.Player(iframeXs);
    playerXs.getVideoTitle().then(function (title) {
      console.log("title:", title);
      setTitle(title);
    });
    playerXs.getDuration().then(function (duration) {
      console.log("duration:", duration);
      setDuration(duration);
    });
    playerXs.on("play", function () {
      console.log("Play");
      playerXs
        .getDuration()
        .then(function (duration) {
          const durations = duration;
          return durations;
        })
        .then(function (durations) {
          startInterval(durations);
        });
    });
    playerXs.on("pause", function () {
      console.log("Pause");
      stopInterval();
    });

    const iframeSm = document.querySelector(".iFrameSm");
    const playerSm = new Vimeo.Player(iframeSm);
    playerSm.getVideoTitle().then(function (title) {
      console.log("title:", title);
      setTitle(title);
    });
    playerSm.getDuration().then(function (duration) {
      console.log("duration:", duration);
      setDuration(duration);
    });
    playerSm.on("play", function () {
      console.log("Play");
      playerSm
        .getDuration()
        .then(function (duration) {
          const durations = duration;
          return durations;
        })
        .then(function (durations) {
          startInterval(durations);
        });
    });
    playerSm.on("pause", function () {
      console.log("Pause");
      stopInterval();
    });

    const iframeMd = document.querySelector(".iFrameMd");
    const playerMd = new Vimeo.Player(iframeMd);
    playerMd.getVideoTitle().then(function (title) {
      console.log("title:", title);
      setTitle(title);
    });
    playerMd.getDuration().then(function (duration) {
      console.log("duration:", duration);
      setDuration(duration);
    });
    playerMd.on("play", function () {
      console.log("Play");
      playerMd
        .getDuration()
        .then(function (duration) {
          const durations = duration;
          return durations;
        })
        .then(function (durations) {
          startInterval(durations);
        });
    });
    playerMd.on("pause", function () {
      console.log("Pause");
      stopInterval();
    });
  };

  const onePercentProgress = () => {
    if (user.user_id) {
      try {
        const accessToken = localStorage.getItem("accessToken");
        Axios.post(`https://apicourse.jknowledgetutor.com/updateProgress`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
          user_id: user.user_id,
          course_id: lesson[0].courses_id,
          lesson_id: lesson[0].lesson_id,
        }).then((res) => {
          console.log('UpdateProgress',res.data);
          // if (res.data.message === 'CreateNew') {
            try {
              Axios.post(
                `https://apicourse.jknowledgetutor.com/getLessonProgress/${user.user_id}`,{
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                  },
                }
              ).then((res) => {
                setProgress(res.data);
              });
            } catch (err) {
              console.log(err);
            }
          // }else{
          //   setProgress(progressLocal);
          // }
        });
      } catch (error) {
        console.log(error);
      }
    }
    // if (progress) {
    //   //Trigger Progress
    //   const findProgressKey = progress.find(
    //     (item) =>
    //       item.course_id === lesson[0].courses_id &&
    //       item.lesson_id === lesson[0].lesson_id
    //   );
    //   if (findProgressKey) {
    //     const counttrigger = findProgressKey.time_progress + 1;
    //     setprogresstrigger(counttrigger);
    //   }
    // }
  };

  const startInterval = (durationTime) => {
    const onePercentPass = (durationTime / 100) * 1000;
    setIntervalLoop(setInterval(onePercentProgress, onePercentPass));
  };

  const stopInterval = () => {
    // console.log("StopInterval");
    setIntervalLoop(null);
  };

  useEffect(() => {
    // console.log("ClearInterval");
    return () => {
      clearInterval(interval);
    };
  }, [interval]);

  // useEffect(() => {
  //   // console.log("ClearInterval");
  //   if (progress) {
  //     setProgressLocal(progress);
  //   }
  // }, [progress]);

  // useEffect(() => {
  //   if (progress) {
  //     //Update Progress
  //     const findProgressKey = progress.find(
  //       (item) =>
  //         item.course_id === lesson[0].courses_id &&
  //         item.lesson_id === lesson[0].lesson_id
  //     );
  //     if (findProgressKey) {
  //       findProgressKey.time_progress += 1;
  //     }
  //     setProgressLocal(progress);
  //   }
  // }, [progresstrigger]);

  try {
    if (lesson) {
      return (
        <>
          <Script
            url="https://player.vimeo.com/api/player.js"
            onLoad={handleLoadScript}
          />

          <Box>
            <Stack direction="row" justifyContent="space-between">
              <Box flex={3} sx={{ display: { xs: "none", sm: "block" } }}>
                <Box borderRadius={5} m={2} p={2}>
                  <Tablofcontents
                    lesson={allLesson}
                    coursesSlug={coursesSlug}
                    progress={progress}
                  />
                </Box>
              </Box>
              <Box flex={9}>
                <Box borderRadius={5} p={1} m={1}>
                  <Box
                    sx={{ display: { xs: "block", sm: "none", md: "none" } }}
                  >
                    <iframe
                      width="100%"
                      height="250"
                      src={lesson[0].lesson_video}
                      title={lesson[0].lesson_name}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      frameborder="no"
                      className="iFrameXs"
                    />
                  </Box>
                  <Box
                    sx={{ display: { xs: "none", sm: "block", md: "none" } }}
                  >
                    <iframe
                      width="100%"
                      height="400"
                      src={lesson[0].lesson_video}
                      title={lesson[0].lesson_name}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      frameborder="no"
                      className="iFrameSm"
                    />
                  </Box>
                  <Box
                    sx={{ display: { xs: "none", sm: "none", md: "block" } }}
                  >
                    <iframe
                      width="100%"
                      height="550"
                      src={lesson[0].lesson_video}
                      title={lesson[0].lesson_name}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      frameborder="no"
                      className="iFrameMd"
                    />
                  </Box>
                  <Box bgcolor="white" borderRadius={2} marginTop={1} p={1}>
                    <Typography variant="h5" color="black">
                      {lesson[0].lesson_name} <br />
                    </Typography>
                    <Typography variant="body2" color="black">
                      {lesson[0].lesson_detail}
                    </Typography>
                    {/* <Typography variant="body1" color="white">
                                            Control Panel : {lesson[0].lesson_control}
                                        </Typography> */}
                  </Box>
                </Box>
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box flex={12} sx={{ display: { xs: "block", sm: "none" } }}>
                <Box borderRadius={5} mx={2} px={3}>
                  <Tablofcontents
                    lesson={allLesson}
                    coursesSlug={coursesSlug}
                    progress={progress}
                  />
                </Box>
              </Box>
            </Stack>
          </Box>
        </>
      );
    }
  } catch (error) {
    console.log(error);
    window.location = "/courses";
  }
}

export default CourseLessonContent;

import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions, Stack } from "@mui/material";
import CreditPayment from "./payment-components/CheckoutCreditCard";
import Axios from "axios";
import Box from "@mui/material/Box";
import Flickity from "react-flickity-component";
import "./Flickity.css";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function Subpack_List() {
  const [packageList, setPackageList] = useState();
  useEffect(() => {
    try {
      Axios.get(`https://apicourse.jknowledgetutor.com/getPackage`).then((res) => {
        setPackageList(res.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const [isHovered, setIsHovered] = useState(false);
  const [focusHoverOut, setFocusHoverOut] = useState(false);
  const [keyFocus, setKeyFocus] = useState(2);

  // function handleMouseOver() {
  //     setIsHovered( true );
  // }
  const scrollToPurchase = () => {
    window.scrollTo(
      {
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth"
      }
    );
    console.log("scrollToPurchase");
  };

  


  const showCompare = (key) => {
    if (packageList && key != packageList.length + 1) {
      const plan = packageList.find((item) => item.plans_id === key);
      const planPro = packageList.find((item) => item.plans_id === key + 1);
      return (
        <>
          <Stack direction="row" alignItems="center" justifyContent="center">
            {/* <Box
              flex={6}
              borderRadius={3}
              sx={{
                display: { xs: "flex", md: "flex" },
                m: 1,
                p: 1,
                bgcolor: "#fff",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: "1rem", color: "black" }}>
                  แพ็กเกจ
                </Typography>
                <Typography sx={{ fontSize: "1rem", color: "black" }}>
                  ราคา
                </Typography>
                <Typography sx={{ fontSize: "1rem", color: "black" }}>
                  จำนวน
                </Typography>
                <Typography sx={{ fontSize: "1rem", color: "black" }}>
                  เฉลี่ย ต่อวัน
                </Typography>
                <Typography sx={{ fontSize: "1rem", color: "black" }}>
                  ประหยัดขึ้น
                </Typography>
              </Box>
            </Box> */}
            <Box
              flex={12}
              borderRadius={3}
              sx={{
                display: { xs: "flex", md: "flex" },
                m: 1,
                p: 1,
                bgcolor: "#e0e0e0ab",
                justifyContent: "center",
              }}
            >
              <Box>
                <Box
                  borderRadius={3}
                  sx={{
                    p: 1,
                    bgcolor: "#a0d64b",
                    textAlign: "center",
                    marginBottom: 1.5,
                  }}
                >
                  <Typography sx={{ fontSize: "1.1rem", color: "white" }}>
                    {plan.name}
                  </Typography>
                </Box>
                
                <Typography sx={{ color: "black" }}
                fontSize= {{ xs: "0.8rem", sm:"0.9rem", md: "1rem" }}>
                <IconButton
              size="small"
              color="inherit"
            >
              <FileDownloadDoneIcon  sx={{color:"#11626b"}}/>
            </IconButton>ราคา 
                  <Typography
                  fontSize= {{ xs: "0.8rem", sm:"0.9rem", md: "1.1rem" }}
                      sx={{
                        color: "black",
                        display: "inline",
                        marginLeft: 1,
                        marginRight: 1,
                        color:"#76a927;"
                      }}
                    >
                      {plan.price}
                      </Typography> บาท
                </Typography>
                <Typography sx={{ color: "black" }}
                fontSize= {{ xs: "0.8rem", sm:"0.9rem", md: "1rem" }}
                >
                <IconButton
              size="small"
              color="inherit"
            >
              <FileDownloadDoneIcon  sx={{color:"#11626b"}}/>
            </IconButton>จำนวน 
                  <Typography
                  fontSize= {{ xs: "0.8rem", sm:"0.9rem", md: "1.1rem" }}
                      sx={{
                        color: "black",
                        display: "inline",
                        marginLeft: 1,
                        marginRight: 1,
                        color:"#76a927;"
                      }}
                    >
                       {plan.duration_days}
                    </Typography>
                   วัน
                </Typography>
                <Typography sx={{ color: "black" }}
                fontSize= {{ xs: "0.8rem", sm:"0.9rem", md: "1rem" }}
                >
                <IconButton
              size="small"
              color="inherit"
            >
              <FileDownloadDoneIcon  sx={{color:"#11626b"}}/>
            </IconButton> เฉลี่ย 
                  <Typography
                  fontSize= {{ xs: "0.8rem", sm:"0.9rem", md: "1.1rem" }}
                      sx={{
                        color: "black",
                        display: "inline",
                        marginLeft: 1,
                        marginRight: 1,
                        color:"#76a927;"
                      }}
                    >
                        {plan.priceperday}
                    </Typography>
                 
                </Typography>
                <Typography sx={{ color: "black" }}
                fontSize= {{ xs: "0.8rem", sm:"0.9rem", md: "1rem" }}
                >
                <IconButton
              size="small"
              color="inherit"
            >
              <FileDownloadDoneIcon  sx={{color:"#11626b"}}/>
            </IconButton>ประหยัดขึ้น 
                  <Typography
                  fontSize= {{ xs: "0.8rem", sm:"0.9rem", md: "1.1rem" }}
                      sx={{
                        color: "black",
                        display: "inline",
                        marginLeft: 1,
                        marginRight: 1,
                        color:"#4f7612;"
                      }}
                    >
                        {plan.discount_guide}
                    </Typography>
                </Typography>
                <Stack
                  sx={{
                    textAlign: "center",
                    paddingTop: 1,
                    paddingBottom: 0,
                  }}
                >
                  <Box component="form">
                    <Button
                      variant="contained"
                      id="credit-card"
                      type="button"
                      sx={{
                        paddingY: 0.5,
                        marginTop: 1,
                        marginBottom: 1,
                        borderRadius: 5,
                        width:120
                      }}
                    >
                      ชำระเงิน
                    </Button>
                  </Box>
                </Stack>
              </Box>
            </Box>

            {planPro ? (
              <Box
                flex={16}
                borderRadius={5}
                sx={{
                  display: { xs: "flex", md: "flex" },
                  m: 1,
                  p: 1,
                  bgcolor: "#e0e0e0ab",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <Box
                    borderRadius={3}
                    sx={{
                      p: 1,
                      bgcolor: "#d98407",
                      textAlign: "center",
                      marginBottom: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: "1.2rem", color: "white" }}>
                      {planPro.name}
                    </Typography>
                  </Box>
                  <Typography sx={{  color: "black" }}
                  fontSize= {{ xs: "0.8rem", sm:"0.9rem", md: "1.1rem" }}
                  >
                  <IconButton
              size="small"
              color="inherit"
            >
              <CheckCircleIcon  sx={{color:"#11626b"}}/>
            </IconButton>ราคา
                    <Typography
                    fontSize= {{ xs: "0.9rem", sm:"1.1rem", md: "1.2rem" }}
                      sx={{
                        color: "black",
                        display: "inline",
                        marginLeft: 1,
                        marginRight: 1,
                        color:"#d98407;"
                      }}
                    >
                      {planPro.price}
                    </Typography>
                    บาท
                  </Typography>
                  <Typography 
                  sx={{
                   color: "black" }}
                   fontSize= {{ xs: "0.8rem", sm:"0.9rem", md: "1.1rem" }}
                   >
                  <IconButton
              size="small"
              color="inherit"
            >
              <CheckCircleIcon  sx={{color:"#11626b"}}/>
            </IconButton>จำนวน
                    <Typography
                    fontSize= {{ xs: "0.9rem", sm:"1.1rem", md: "1.2rem" }}
                      sx={{
                        color: "black",
                        display: "inline",
                        marginLeft: 1,
                        marginRight: 1,
                        color:"#d98407;"
                      }}
                    >
                      {planPro.duration_days}
                    </Typography>
                    วัน
                  </Typography>
                  <Typography sx={{  color: "black" }}
                  fontSize= {{ xs: "0.8rem", sm:"0.9rem", md: "1.1rem" }}>
                  <IconButton
              size="small"
              color="inherit"
            >
              <CheckCircleIcon  sx={{color:"#11626b"}}/>
            </IconButton>เฉลี่ย
                    <Typography
                    fontSize= {{ xs: "0.9rem", sm:"1.1rem", md: "1.2rem" }}
                      sx={{
                        color: "black",
                        display: "inline",
                        marginLeft: 1,
                        marginRight: 1,
                        color:"#d98407;"
                      }}
                    >
                      {planPro.priceperday}
                    </Typography>
                  </Typography>
                  <Typography sx={{ color: "black" }} 
                  fontSize= {{ xs: "0.8rem", sm:"0.9rem", md: "1.1rem" }}
                  >
                  <IconButton
              size="small"
              color="inherit"
            >
              <CheckCircleIcon sx={{color:"#11626b"}}/>
            </IconButton>ประหยัดขึ้น
                    <Typography
                    fontSize= {{ xs: "0.9rem", sm:"1.1rem", md: "1.2rem" }}
                      sx={{
                        color: "black",
                        display: "inline",
                        marginLeft: 1,
                        marginRight: 1,
                        color:"#b32b00;"
                      }}
                    >
                      {planPro.discount_guide}
                    </Typography>
                  </Typography>
                  <Stack
                    sx={{
                      textAlign: "center",
                      paddingTop: 1,
                      paddingBottom: 0,
                    }}
                  >
                    <Box component="form">
                      <Button
                        variant="contained"
                        id="credit-card"
                        type="button"
                        sx={{
                          paddingY: 0.5,
                          marginTop: 1,
                          marginBottom: 1,
                          borderRadius: 5,
                          width:120
                        }}
                      >
                        ชำระเงิน
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            ) : null}
          </Stack>
        </>
      );
    }
  };

  const handleMouseClick = (key) => () => {
    setKeyFocus(key);
  };

  function handleMouseOut() {
    setFocusHoverOut(true);
    setIsHovered(false);
  }

  try {
    if (packageList) {
      return (
        <>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Box sx={{ m: 3, width: { xs: "800px", md: "1100px" } }}>
              <Typography sx={{ fontSize: "1.6rem" }}>แพ็คเกจราคา</Typography>
              <Box
                sx={{
                  m: 1,
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Box sx={{ m: 2 }}>
                  {packageList.map((val, key) => {
                    return (
                      <>
                        {key === 2 ? (
                          <Card
                            onClick={handleMouseClick(key)}
                            sx={
                              focusHoverOut === false
                                ? {
                                    transition: "transform .5s",
                                    transform: "scale(1.08)",
                                    boxShadow: "0px 3px 3px 3px #a0d64b;",
                                    display: "inline-block",
                                    maxWidth: {xs:130,sm:180},
                                    margin: 1,
                                    borderRadius: 5,
                                  }
                                : {
                                    transition: "transform .5s",
                                    transform: "scale(1)",
                                    display: "inline-block",
                                    maxWidth: {xs:130,sm:180},
                                    "&:hover": {
                                      transition: "transform .5s",
                                      transform: "scale(1.08)",
                                      boxShadow: "0px 3px 3px 3px #a0d64b;",
                                    },
                                    margin: 1,
                                    borderRadius: 5,
                                  }
                            }
                            key={key}
                          >
                            <CardActionArea>
                              <CardMedia
                                component="img"
                                image={val.img}
                                alt="package"
                                sx={{ height: {xs:20,sm:40}}}
                              />
                              <CardContent sx={{ height: {xs:110,sm:100}, padding: {xs:1,sm:3} }}>
                                <Typography
                                  gutterBottom
                                  fontSize={{xs:"1rem",sm:"1.2rem"}}
                                  component="div"
                                >
                                  {val.name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {val.detail}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                            <Stack
                              sx={{
                                textAlign: "center",
                                paddingTop: {xs:1,sm:3},
                                paddingBottom: 1,
                              }}
                            >
                              <Box component="form">
                                <Button
                                  variant="contained"
                                  id="credit-card"
                                  type="button"
                                  sx={{
                                    paddingY: 0.5,
                                    marginTop: 1,
                                    marginBottom: 1,
                                    borderRadius: 5,
                                  }}
                                  onClick={scrollToPurchase}
                                >
                                  เลือก
                                </Button>
                              </Box>
                            </Stack>
                          </Card>
                        ) : (
                          <Card
                            onMouseOver={handleMouseOut}
                            onClick={handleMouseClick(key)}
                            sx={{
                              maxWidth: {xs:130,sm:180},
                              "&:hover": {
                                transition: "transform .5s",
                                transform: "scale(1.08)",
                                boxShadow: "0px 3px 3px 3px #a0d64b;",
                              },
                              display: "inline-block",
                              margin: 1,
                              borderRadius: 5,
                            }}
                            key={key}
                          >
                            <CardActionArea>
                              <CardMedia
                                component="img"
                                image={val.img}
                                alt="package"
                                sx={{ height: {xs:20,sm:40}}}
                              />
                              <CardContent sx={{ height: {xs:110,sm:100}, padding: {xs:1,sm:3} }}>
                                <Typography
                                  gutterBottom
                                  fontSize={{xs:"1rem",sm:"1.2rem"}}
                                  component="div"
                                >
                                  {val.name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {val.detail}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                            <Stack
                              sx={{
                                textAlign: "center",
                                paddingTop: {xs:1,sm:3},
                                paddingBottom: 1,
                              }}
                            >
                              <Box component="form">
                                <Button
                                  variant="contained"
                                  id="credit-card"
                                  type="button"
                                  sx={{
                                    paddingY: 0.5,
                                    marginTop: 1,
                                    marginBottom: 1,
                                    borderRadius: 5,
                                  }}
                                  onClick={scrollToPurchase}
                                >
                                  เลือก
                                </Button>
                              </Box>
                            </Stack>
                          </Card>
                        )}
                      </>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Box sx={{ m: 3, width: { xs: "800px", md: "900px" } }}>
              {showCompare(keyFocus + 1)}
            </Box>
          </Stack>
        </>
      );
    }
  } catch (error) {
    console.log(error);
  }
}

import React from 'react'
import "./Content.css"
import study from "../asset/study.jpg"
import school from "../asset/school.jpg"
import student from "../asset/student.jpg"
import Image from 'mui-image'
import Typography from '@mui/material/Typography';

function Content() {
    return (
        <div className="container">
            <section className="content-con">
                <div className="content-l">
                    <Image src={study} alt=""
                        duration={500}
                        easing="ease-out"
                        showLoading={true}
                        shift="right"
                        distance="100px"
                        shiftDuration={1500}
                         />
                </div>
                <div className="content-r">
                <Typography gutterBottom variant="h5" component="div">
                    เรียนได้ ทุกที่ ทุกเวลา
                </Typography>
                <Typography gutterBottom component="div">
                 คอร์สเรียนคุณภาพ จากติวเตอร์ชั้นนำระดับประเทศ ด้วยประสบการณ์การสอน และชำนาญในข้อสอบทุกระดับ นำมาปรับใช้ให้น้องๆได้ฝึกฝน ทั้งการมอบความรู้คู่ความสนุก การเรียนรู้ด้วยการลงมือทำจริง การสรุปสูตร มากเทคนิคในการพิชิตข้อสอบ
                </Typography>
                    
                </div>
            </section>
            <section className="content-con">
                <div className="content-r">
                <Typography gutterBottom variant="h5" component="div">
                    ที่สุดของเนื้อหาวิชาการ เข้มข้น
                </Typography>
                <Typography gutterBottom component="div">
                    ด้วยประสบการณ์การสอนกว่า 20 ปี ที่ J Knowledge ได้ถ่ายทอดความรู้วิชาการ ทั้งการสอน การแนะแนว เทคนิคต่างๆ รวมกันเป็นแนวการสอนที่เป็นเอกลักษณ์เฉพาะตัว ที่พบได้เฉพาะที่นี่…ที่เดียวนั้น
                </Typography>
                    
                </div>
                <div className="content-l">
                    <Image src={student} alt=""
                        duration={500}
                        easing="ease-out"
                        showLoading={true}
                        shift="left"
                        distance="100px"
                        shiftDuration={1500} />
                </div>
            </section>
            <section className="content-con">
                <div className="content-l">
                    <Image src={school} alt=""
                        duration={500}
                        easing="ease-out"
                        showLoading={true}
                        shift="right"
                        distance="100px"
                        shiftDuration={1500}
                         />
                </div>
                <div className="content-r">
                <Typography gutterBottom variant="h5" component="div">
                    ที่สุดกับประสบการณ์กว่า 20 ปี
                </Typography>
                <Typography gutterBottom component="div">
                ด้วยประสบการณ์การสอนกว่า 20 ปี ที่ J Knowledge ได้ถ่ายทอดความรู้วิชาการ ทั้งการสอน การแนะแนว เทคนิคต่างๆ รวมกันเป็นแนวการสอนที่เป็นเอกลักษณ์เฉพาะตัว ที่พบได้เฉพาะที่นี่…ที่เดียวนั้น
                </Typography>
                    
                </div>
            </section>
        </div>

    )
}

export default Content

import React from 'react'
import "./Banner.css"
import Typography from '@mui/material/Typography';
let bannerData = {
    title: "J Knowledge Tutor",
    desc: "กว่า 20 ปี ที่สถาบันกวดวิชา เจ โนว์เลจ ได้สร้างฝันให้น้อง ๆ นักเรียนระดับชั้นมัธยมศึกษาตอนปลายมากมาย ",
    descII: "ร่วมสร้างกิจกรรม พัฒนา ขยายโอกาส ยกระดับการเรียนรู้ บูรณาการศาสตร์ในทุกแขนง เพื่อตอบโจทย์ต่อการศึกษาในปัจจุบัน โดยเฉพาะยุคแห่งการพัฒนาอย่างไม่หยุดนิ่งในศตวรรษที่ 21 "
}

function Banner() {
    return (
        <div className="banner-bg">
            <div className="container">
                <div className="banner-con ">
                    <div className="banner-text ">
                        <div className='banner-con-text'>
                        <Typography gutterBottom variant="h4" component="div">
                            {bannerData.title}
                        </Typography>
                            <div>
                            <Typography sx={{ fontSize: '1.2rem', fontWeight: 600 }}>
                            {bannerData.desc}
                            </Typography>
                            <Typography sx={{ fontSize: '1.2rem', fontWeight: 600, display: { xs: 'none', md: 'block' } }}>
                            {bannerData.descII}
                            </Typography>
                            </div>

                        </div>

                        {/* <a href="https://jknowledgetutor.com" className="banner-btn">Learn More</a> */}
                    </div>  
                </div>
            </div>
        </div>
    )
}

export default Banner

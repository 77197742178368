import React, { useState, useEffect, createContext, useContext } from "react";
import Axios from "axios";


export const UserContext = createContext();
export default ({children}) => {
  const [user, setUser] = useState();
  const [token, setToken] = useState('');
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
    Axios.post("https://apicourse.jknowledgetutor.com/authsignin", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((res) => {
      if (res.data.status === "ok") {
        const accessToken = localStorage.getItem("accessToken");
        Axios.post(`https://apicourse.jknowledgetutor.com/getAUser/${res.data.decoded.user_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }).then((res) => {
          setUser(res.data[0]);
          setAuthenticated(true);
        });
      } 
    });
  }, []);
  return (
      <UserContext.Provider value={{user,setUser,token,setToken,authenticated,setAuthenticated}} >
          {children}
      </UserContext.Provider>
    );
}




import React, { useState, useEffect,useContext} from 'react'
import Appbar from '../components/Appbar'
import Footer from '../components/Footer'
import CoursesContent from '../components/CoursesContent';
import Axios from 'axios'
import { useParams } from 'react-router-dom';
import LoadingScreen from 'react-loading-screen'
import { UserContext } from "../context/AuthContext";


function CoursesContents() {
  const {user , authenticated} = useContext(UserContext);

  const { coursesSlug } = useParams();
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const accessToken = localStorage.getItem('accessToken')
  //   Axios.post('https://apicourse.jknowledgetutor.com/authsignin', {
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": "Bearer " + accessToken
  //     }
  //   })
  //     .then((res) => {
  //       if (res.data.status === 'ok') {
  //         // console.log('Valid Token');
  //         setLoading(false)
  //       } else {
  //         alert('Invalid Token!, Please login.')
  //         localStorage.removeItem('accessToken')
  //         window.location = '/login'
  //       }
  //     })
  // }, []);

  return (
    <>
      {/* <LoadingScreen loading={loading}
        bgColor="rgba(255,255,255,0.8)"
        spinnerColor="#478e6b"
        textColor="#676767"
        logoSrc="http://localhost:3000/static/media/Jknowledge-Logo.18250765f4f4ae91679a.webp"
        text="" /> */}
      {authenticated ? <Appbar user={user}/> : <Appbar/>}
      {authenticated ? <CoursesContent user={user} coursesSlug={coursesSlug} /> : <CoursesContent coursesSlug={coursesSlug} /> }
      <Footer />
    </>
  )
}

export default CoursesContents
import React, { useState, useEffect, useContext } from "react";
import Appbar from "../components/Appbar";
import ProfileHomepage from "../components/profile_components/ProfileHomepage";
import ProfileSetting from "../components/profile_components/ProfileSetting";
import ProfileSidebar from "../components/profile_components/ProfileSidebar";
import ProfileSidebarMin from "../components/profile_components/ProfileSidebarMin";
import ProfileMyCourse from "../components/profile_components/ProfileMyCourse";
import ProfileContent from "../components/profile_components/ProfileContent";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Axios from "axios";
import { UserContext } from "../context/AuthContext";
import Footer from "../components/Footer";



function Profile() {
  // const {user} = useContext(UserContext);
  const [activeContent, setActiveContent] = useState("profile-homepage");
  const {user, authenticated} = useContext(UserContext)
  const [initial, setInitial] = useState(false);

  
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    setActiveContent(localStorage.getItem("ActiveContent"));
    setInitial(true)
  }, [activeContent]);
  useEffect(() => {
    setActiveContent("profile-homepage")
  }, []);
  
  const logOut =() => {
    localStorage.removeItem('accessToken')
    window.location = '/'
  }

  function getContent(activeContent) {
    switch (activeContent) {
      case "profile-homepage":
        return <ProfileHomepage user={user}/>;
      case "profile-mycourse":
        return <ProfileMyCourse user={user}/>;
      case "profile-setting":
        return <ProfileSetting user={user} />;
      default:
        <ProfileHomepage user={user} />;
    }
  }
  return (
    <>
        <Appbar user={user}/>
        {user ? (
          <Box>
            <Stack direction="row">
              <Box
                flex={9}
                sx={{ display: { xs: "flex", sm:"flex", md: "none" }, mt: { xs: 1 },mb: 1 ,mx:1, borderRadius: 5 }}
              >
                 <ProfileSidebarMin
                  user={user}
                  activeContent={activeContent}
                  setActiveContent={setActiveContent}
                />
                
              </Box>
            </Stack>
            <Stack direction="row">
              <Box
                flex={2}
                sx={{ display: { xs: "none", md: "flex" }, m: 2, p: 2 }}
              >
                <ProfileSidebar
                  user={user}
                  activeContent={activeContent}
                  setActiveContent={setActiveContent}
                />
              </Box>
              <Box
                flex={9}
                minWidth={{md:500}}
                sx={{ mt: { xs: 1, md: 5 },mb: 5,mx:2,  p: 2, borderRadius: 5, bgcolor: "#D6E4E5" }}
              >
                <Box
                  flex={10}
                  sx={{
                    minHeight: "100vh",
                  }}
                >
                  {getContent(activeContent)}
                </Box>
              </Box>
            </Stack>
          </Box>
        ) : null}
      <Footer />
    </>
  );
}

export default Profile;

import './App.css';
import { Routes, Route, } from 'react-router-dom';
import Home from './pages/Home';
import Register from './pages/Register';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Payment from './pages/payment';
import Subpack from './pages/SubPackageList';
import Courses from './pages/Courses'
import CreateCourses from './pages/Create_course'
import Logout from './pages/Logout'
import CartPage from './pages/CartPage'
import CoursesContents from './pages/CoursesContents';
import CoursesLesson from './pages/CoursesLesson';
import Profile from './pages/Profile';
import Test from './pages/test'
import UserProvider from "./context/AuthContext";
import GuardRoutes  from './utils/GuardRoutes';


function App() {
  return (
    <UserProvider>
      <Routes>
        <Route element={<GuardRoutes />}>
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          {/* <Route path="/create-courses" element={<CreateCourses />} /> */}
          <Route path="/payment" element={<Payment />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/courses/:coursesSlug" element={<CoursesContents />} />
          <Route path="/courses/:coursesSlug/:lessonSlug" element={<CoursesLesson />} />
          {/* <Route path="/test" element={<Test />} /> */}
          <Route path="/profile" element={<Profile />} />
          <Route path="/subpack" element={<Subpack />} />
        </Route>

        <Route path="/" element={<Home />} />
        <Route path="/homepage" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </UserProvider>
  );
}

export default App;

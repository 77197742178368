import React, { useContext, useEffect, useState } from "react";
import { TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Axios from "axios";
import ActionCard from "../ActionCard";

function ProfileMyCourse({ user }) {
  const [userMeta, setUserMeta] = useState();
  const [newFname, setNewFname] = useState();
  const [newLname, setNewLname] = useState();
  const [newEmail, setNewEmail] = useState();
  const [userCourses, setUserCourses] = useState();
  const [userFavCourses, setUserFavCourses] = useState();
  const [allcourses, setAllcourses] = useState([]);
  const [initial, setInitial] = useState(false);
  const [progress, setProgress] = useState();
  const [filteredID11, setFilteredID11] = useState();

  const showLog = (showLog) => {
    // console.log(...showLog);
    // console.log(progress);
  };
  useEffect(() => {
    try {
      if (user) {
        const accessToken = localStorage.getItem("accessToken");
        Axios.get("https://apicourse.jknowledgetutor.com/courses").then((res) => {
          setAllcourses(res.data);
        });
        Axios.post(`https://apicourse.jknowledgetutor.com/getAUserMeta/${user.user_id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }).then((res) => {
          setUserMeta(res.data);
          setNewFname(user.fname);
          setNewLname(user.lname);
          setNewEmail(user.email);
        });
        Axios.post(`https://apicourse.jknowledgetutor.com/getUserCourses/${user.user_id}`,{
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }).then(
          (res) => {
            setUserCourses(res.data);
            setUserFavCourses(JSON.parse(res.data[0].fav_courses));
          }
        );
        Axios.post(
          `https://apicourse.jknowledgetutor.com/getLessonProgress/${user.user_id}`,{
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
              }
          }
        ).then((res) => {
          setProgress(res.data);
          setInitial(true);
        });
      }
    } catch (error) {}
  }, [user]);

  const [filteredMyCourse, setFilteredMyCourse] = useState();
  const [filteredMyCourseProgress, setFilteredMyCourseProgress] = useState();
  function getUniqueArrayBy(filterExam, key) {
    return [...new Map(filterExam.map((item) => [item[key], item])).values()];
  }
  useEffect(() => {
    if (initial) {
      let filteredMyCourse = [];
      if (userCourses) {
        // (userCourses[0].courses.split(",")).map((tagId) => {
        //             allcourses.filter(
        //                 (course) =>
        //                 {if (course.courses_id == tagId){
        //                     filteredMyCourse.push(course)
        //                 }
        //             })
        // })
        userFavCourses?.map((tagId) => {
          allcourses.filter((course) => {
            if (course.courses_id == tagId.course_id) {
              filteredMyCourse.push(course);
            }
          });
        });
      }
      setFilteredMyCourse(filteredMyCourse);
      if (progress) {
        const uniq = getUniqueArrayBy(progress, "course_id");

        let filteredCourseProgress = [];
        uniq.map((course) => {
          filteredCourseProgress.push(course.course_id);
        });
        let filteredMyCourseProgress = [];
        filteredCourseProgress.map((tagId) => {
          allcourses.filter((course) => {
            if (course.courses_id == tagId) {
              filteredMyCourseProgress.push(course);
            }
          });
        });
        setFilteredMyCourseProgress(filteredMyCourseProgress);
      }
      //M.5
      let filteredID11 = [];
      allcourses.filter((courses) => {
        courses.courses_tag.split(",").map((tagId, key) => {
          if (tagId == 11) {
            //Tag Id to Find
            filteredID11.push(courses);
          }
        });
        setFilteredID11(filteredID11);
        return filteredID11;
      });
    }
  }, [initial, userCourses]);
  useEffect(() => {
    //M.5
    let filteredID11 = [];
    allcourses.filter((courses) => {
      courses.courses_tag.split(",").map((tagId, key) => {
        if (tagId == 11) {
          //Tag Id to Find
          filteredID11.push(courses);
        }
      });
      setFilteredID11(filteredID11);
      return filteredID11;
    });
  }, [initial]);

  return (
    <>
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Box m={2}>
          <Typography
            sx={{
              display: "inline",
              fontSize: "1.8rem",
              borderBottom: "4px solid #a0d64b",
            }}
          >
            คอร์สเรียนของฉัน
          </Typography>
        </Box>

        {progress ? <Typography>{showLog(progress)}</Typography> : null}
        {/* Primary */}
        <Stack direction="row" alignItems="center" justifyContent="left">
          <Box sx={{ my: 3 }}>
            <Typography sx={{ fontSize: "1.1rem" }}> เรียนต่อ </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="left"
              sx={{ flexWrap: "wrap" }}
            >
              {filteredMyCourseProgress?.map((val, key) => {
                return (
                  <Box sx={{ mx: 1 }} key={key} margin={2}>
                    <ActionCard
                      userId={user.user_id}
                      coursesId={val.courses_id}
                      coursesName={val.courses_name}
                      coursesDetail={val.courses_detail}
                      coursesPic={val.courses_pic}
                      coursesSlug={val.courses_slug}
                      coursesTag={val.courses_tag}
                      courseTime={val.courses_video_length}
                      courseArticle={val.courses_num_articles}
                      courseFav={
                        userFavCourses?.findIndex(
                          (course) => course.course_id === val.courses_id
                        ) === -1
                          ? null
                          : true
                      }
                      userFavCourses={userFavCourses}
                      setUserFavCourses={setUserFavCourses}
                    />
                  </Box>
                );
              })}
            </Stack>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="left">
          <Box sx={{ my: 3 }}>
            <Typography sx={{ fontSize: "1.1rem" }}> ชื่นชอบ </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="left"
              sx={{ flexWrap: "wrap" }}
            >
              {filteredMyCourse?.map((val, key) => {
                return (
                  <Box sx={{ mx: 1 }} key={key} margin={2}>
                    <ActionCard
                      userId={user.user_id}
                      coursesId={val.courses_id}
                      coursesName={val.courses_name}
                      coursesDetail={val.courses_detail}
                      coursesPic={val.courses_pic}
                      coursesSlug={val.courses_slug}
                      coursesTag={val.courses_tag}
                      courseTime={val.courses_video_length}
                      courseArticle={val.courses_num_articles}
                      courseFav={
                        userFavCourses?.findIndex(
                          (course) => course.course_id === val.courses_id
                        ) === -1
                          ? null
                          : true
                      }
                      userFavCourses={userFavCourses}
                      setUserFavCourses={setUserFavCourses}
                    />
                  </Box>
                );
              })}
            </Stack>
          </Box>
        </Stack>
        {/* M.5 */}
        <Stack direction="row" alignItems="center" justifyContent="left">
          <Box sx={{ my: 3 }}>
            <Typography sx={{ fontSize: "1.1rem" }}>
              มัธยมศึกษาปีที่ 5
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="left"
              sx={{ flexWrap: "wrap" }}
            >
              {filteredID11?.map((val, key) => {
                return (
                  <Box sx={{ mx: 1 }} key={key} margin={2}>
                    <ActionCard
                      userId={user.user_id}
                      coursesId={val.courses_id}
                      coursesName={val.courses_name}
                      coursesDetail={val.courses_detail}
                      coursesPic={val.courses_pic}
                      coursesSlug={val.courses_slug}
                      coursesTag={val.courses_tag}
                      courseTime={val.courses_video_length}
                      courseArticle={val.courses_num_articles}
                      courseFav={
                        userFavCourses?.findIndex(
                          (course) => course.course_id === val.courses_id
                        ) === -1
                          ? null
                          : true
                      }
                      userFavCourses={userFavCourses}
                      setUserFavCourses={setUserFavCourses}
                    />
                  </Box>
                );
              })}
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
}

export default ProfileMyCourse;

import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import Box from "@mui/material/Box";
import SendIcon from "@mui/icons-material/Send";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import Chip from "@mui/material/Chip";
import Axios from "axios";
import ArticleIcon from "@mui/icons-material/Article";
import FavoriteIcon from "@mui/icons-material/Favorite";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

export default function MultiActionAreaCard({
  userId,
  coursesName,
  coursesDetail,
  coursesPic,
  coursesSlug,
  coursesTag,
  courseTime,
  courseArticle,
  userFavCourses,
  setUserFavCourses,
  courseFav,
  coursesId
}) {
  const [courseTag, setCourseTag] = useState();
  const TagManage = (tagIn) => {
    if (coursesTag && courseTag && tagIn) {
      if (tagIn) {
        const Array = tagIn.split(",");
        const mapArray = Array.map((val, key) => {
          const filteredData = courseTag.filter((tag) => tag.tag_id == val)[0];
          return filteredData;
        });
        const result = mapArray.map(({ tag_name, tag_color }) => ({
          tag_name,
          tag_color,
        }));
        return result;
      }
    }
  };

  const favCourse = (courseId) => {
    if (userId) {
      const checkIndex = userFavCourses.findIndex(
        (course) => course.course_id === courseId
      );
      if (checkIndex !== -1) {
        //
        console.log('Not yet');
      } else {
        console.log('Already');
        setUserFavCourses((userFavCourses) => [
          ...userFavCourses,
          { course_id: courseId },
        ]);
        const favCourseConst = (courseId) => [...userFavCourses, { course_id: courseId }];
        const updateCourse = favCourseConst(courseId);
        console.log('userFavCourses favCourse',updateCourse);
        Axios.put("https://apicourse.jknowledgetutor.com/updateFavCourses", {
          // course_id: courseId,
          user_id: userId,
          fav_courses: JSON.stringify(updateCourse),
        });
      }
    }
  };

  const unFavCourse = (courseId) => {
    if (userId) {
      setUserFavCourses((userFavCourses) =>
        userFavCourses.filter((course) => course.course_id !== courseId)
      );
      let updateCourse = userFavCourses.filter((course) => course.course_id !== courseId)
      console.log('userFavCourses unFavCourse',updateCourse);
      Axios.put("https://apicourse.jknowledgetutor.com/updateFavCourses", {
        // course_id: courseId,
        user_id: userId,
        fav_courses: JSON.stringify(updateCourse),
      });
    }
  };

  
  useEffect(() => {
    try {
      Axios.get(`https://apicourse.jknowledgetutor.com/getCourseTag`).then((res) => {
        setCourseTag(res.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <>
      <Box sx={{ position: "relative" }}>
        {courseFav == true ? (
          // <Tooltip title="ยกเลิกการชื่นชอบ"  sx={{position: "absolute"}}>
            // <IconButton onClick={() => unFavCourse(coursesId)}  sx={{position: "absolute"}}>
            <Tooltip title="ยกเลิกการชื่นชอบ">
            <BookmarkIcon
                sx={{
                  position: "absolute",
                  zIndex: 10,
                  right: -5,
                  top: -10,
                  fontSize: { xs: 45, sm: 45 },
                  color: "#FBDF07",
                }}
                onClick={() => unFavCourse(coursesId)} 
              />
             </Tooltip>
            // </IconButton>
          // </Tooltip>
        ) : (
          // <Tooltip title="ชื่นชอบ" sx={{position: "absolute"}}>
            // <IconButton onClick={() => favCourse(coursesId)} sx={{position: "absolute"}}>
            <Tooltip title="ชื่นชอบ">

              <BookmarkIcon
                sx={{
                  position: "absolute",
                  zIndex: 10,
                  right: -5,
                  top: -10,
                  fontSize: { xs: 45, sm: 45 },
                  color: "#606060a6",
                }}
                onClick={() => favCourse(coursesId)}
               
              />
             </Tooltip>

            // </IconButton>
          // </Tooltip>
        )}
      </Box>

      {/* <Box
        sx={{
          position: "relative",
        }}
      >
        <BookmarkIcon
          sx={{
            position: "absolute",
            zIndex: 10,
            right: -5,
            top: -10,
            fontSize: { xs: 40, sm: 50 },
            color: courseFav == true ? "#FBDF07" : "#606060a6",
          }}
          //#606060a6
        />
      </Box> */}

      <Card
        sx={{
          borderRadius: { xs: 5, sm: 7 },
          maxWidth: { xs: 135, sm: 180 },
          minWidth: { xs: 125, sm: 170 },
          maxHeight: { xs: 220, sm: 360 },
          "&:hover": {
            transition: "transform .3s",
            // transform: "scale(1.06)"
          },
        }}
        elevation={2}
      >
        <CardActionArea href={`/courses/${coursesSlug}`}>
          <CardMedia
            component="img"
            sx={{
              height: { xs: 50, sm: 120 },
            }}
            image={coursesPic}
            alt={coursesName}
          />
          <CardContent sx={{ paddingBottom: 0, paddingTop: 1 }}>
            {/* <Box sx={{ display: "flex" }}>
              {myFavExam?.findIndex(fav => fav.exam_id === val.exam_id) === -1 ?
              <Tooltip title="ชื่นชอบ">
                <IconButton>
                  {/* onClick={() => onClickFavExam(val.exam_id)} 
                  <FavoriteIcon
                    sx={{
                      display:'none',
                      color: "#E90064"
                      // myFavExam?.findIndex(fav => fav.exam_id === val.exam_id) === -1 ? 'grey' :
                    }}
                  />
                </IconButton>
              </Tooltip>
              :
              <Tooltip title="ยกเลิกการชื่นชอบ">
                <IconButton>
                  onClick={() => onUnFavExam(val.exam_id)}
                  <FavoriteIcon
                    sx={{
                      display:'none',
                      color: "#E90064",
                      myFavExam?.findIndex(fav => fav.exam_id === val.exam_id) === -1 ? 'grey' :
                    }}
                  />
                </IconButton>
              </Tooltip>
              }
            </Box> */}

            <Typography
              gutterBottom
              component="div"
              margin={0}
              fontSize={{ xs: "0.8rem", sm: "1rem" }}
            >
              {coursesName}
            </Typography>
            {/* <Typography noWrap variant="body2" color="text.secondary" margin={0}>
                        {coursesDetail}
                    </Typography> */}
            <Typography
              fontSize={12}
              color="#616161"
              sx={{ display: "inline-flex" }}
            >
              <Box py={0} sx={{ display: "inline-flex" }}>
                <AccessTimeFilledIcon sx={{ fontSize: 12 }} />
              </Box>
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  marginLeft: 0.5,
                  marginRight: 0.5,
                }}
              >
                {courseTime} ชั่วโมง
              </Box>
            </Typography>
            <Typography
              //   variant="body2"
              fontSize={12}
              color="#616161"
              sx={{ display: "inline-flex" }}
            >
              <Box py={0} sx={{ display: "inline-flex" }}>
                <ArticleIcon sx={{ fontSize: 12 }} />
              </Box>
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  marginLeft: 0.5,
                }}
              >
                {courseArticle} บทเรียน
              </Box>
            </Typography>
          </CardContent>
          <Box display={"flex"} justifyContent={"center"}>
            {coursesTag
              ? TagManage(coursesTag)?.map((val, key) => {
                  return (
                    <>
                      <Chip
                        // label={val.tag_name}
                        key={key}
                        sx={{
                          backgroundColor: `${val.tag_color}`,
                          borderRadius: 3,
                          margin: 0.1,
                          fontSize: 0,
                          height: 10,
                          width: 45,
                        }}
                      />
                    </>
                  );
                })
              : null}
          </Box>
        </CardActionArea>
        <CardActions sx={{ justifyContent: "center" }}>
          {/* <Button sx={{ borderRadius: 5 }} size="small" variant='outlined' startIcon={<PlaylistAddIcon />} color="primary">
                    บันทึก
                </Button>
                <Button sx={{ borderRadius: 5 }} size="small" href={`/courses/${coursesId}`} variant='outlined' startIcon={<SendIcon />} color="success">
                    เข้าเรียน
                </Button> */}
          <Button
            variant="contained"
            color="secondary"
            sx={{ borderRadius: 5, width: { xs: "110px", md: "180px" } }}
            href={`/courses/${coursesSlug}`}
          >
            <Typography
              sx={{ fontWeight: 500 }}
              fontSize={{ xs: "0.8rem", sm: "1rem" }}
            >
              เข้าเรียน
            </Typography>
          </Button>
        </CardActions>
      </Card>
    </>
  );
}

import React, {useContext, useEffect } from 'react'
import SubPack from '../components/Subpack_List'
import Axios from 'axios'
import Appbar from '../components/Appbar'
import { UserContext } from "../context/AuthContext";
import Footer from "../components/Footer";

function SubPackageList() {
  const {user} = useContext(UserContext);

    return (
        <>
            <Appbar user={user}/>
            <SubPack />
            <Footer />    
        </>

    )
}

export default SubPackageList
import React, { useState, useEffect } from "react";
import ActionCard from "./ActionCard";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Axios from "axios";
import Flickity from "react-flickity-component";
import "./Flickity.css";
import Typography from "@mui/material/Typography";

function CoursesListBar({ user }) {
  const [allcourses, setAllcourses] = useState([]);
  const [initial, setInitial] = useState(false);
  const [filteredID20, setFilteredID20] = useState();

  useEffect(() => {
    Axios.get("https://apicourse.jknowledgetutor.com/courses").then((res) => {
      setAllcourses(res.data);
      setInitial(true);
      // console.log(res.data);
    });
  }, []);

  const flickityOptions = {
    initialIndex: 3,
    autoPlay: true,
    selectedAttraction: 0.01,
    friction: 0.15,
    contain: true,
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Box sx={{ m: 3, width: { xs: "800px", md: "1285px" } }}>
          <Typography sx={{ fontSize: "1.6rem" }}>คอร์สเรียนแนะนำ</Typography>
          <Flickity
            className={"carousel"} // default ''
            elementType={"div"} // default 'div'
            options={flickityOptions} // takes flickity options {}
            reloadOnUpdate // default false
            static // default false
          >
            {allcourses.map((val, key) => {
              return (
                <Box sx={{ mx: 1 }} key={key}>
                  <ActionCard
                    coursesName={val.courses_name}
                    coursesDetail={val.courses_detail}
                    coursesPic={val.courses_pic}
                    coursesSlug={val.courses_slug}
                    coursesTag={val.courses_tag}
                    courseTime={val.courses_video_length}
                    courseArticle={val.courses_num_articles}
                  />
                </Box>
              );
            })}
          </Flickity>
        </Box>
      </Stack>
    </>
  );
}

export default CoursesListBar;

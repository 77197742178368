import React from 'react'
import LoginDialog from '../components/Login-component'
import Appbar from '../components/Appbar'
import Footer from "../components/Footer";



function Login() {
    return (
        <>
            <Appbar />
            <LoginDialog />
            <Footer />
        </>
    )
}

export default Login
import { createTheme } from "@mui/material"

export const theme = createTheme({
    typography: {
        fontFamily: "'Prompt', sans-serif",
    },
    palettes: {
        lightGreen: {
            main: "#52734D",
            secondary: '#91C788',
            third: '#DDFFBC',
            text: '#FEFFDE'
        },
        dark: {
            main: "#222831",
            secondary: '#393E46',
            third: '#00ADB5',
            text: '#EEEEEE'
        }
        ,text: '#EEEEEE'
    },    
    mainColor: {
        main: '#7ad2d4',
        second: '#0e3c47'
    },
    palette: {
        secondary: {
            main: '#015352',
        },
        warningOrange: {
            main: '#f57c00'
        },
        greenNeon: {
            main: '#a0d64b'
        },
        backButton: {
            main: '#0081B4'
        },
        greyButton: {
            main: '#D8D8D8'
        }
    },

})


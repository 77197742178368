import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import React, { useContext, useState, useEffect } from "react";
import ProfileDrawer from "../components/profile_components/ProfileDrawer";


function ResponsiveAppBar ({user}) {

  return (
    <AppBar position="sticky" sx={{ background: "#0e3746" }}>
      <Container maxWidth="xl">
        {user ? <ProfileDrawer user={user} /> : <ProfileDrawer />}
      </Container>
      <Box sx={{ height: "5px", bgcolor: "#a0d64b" }} />
    </AppBar>
  );
};
export default ResponsiveAppBar;

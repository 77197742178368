import React, { useState, useEffect, useContext} from 'react';
import { useParams } from 'react-router-dom';
import CourseLessonContent from '../components/CourseLessonContent';
import Appbar from '../components/Appbar'
import LoadingScreen from 'react-loading-screen'
import Axios from 'axios'
import { UserContext } from "../context/AuthContext";
import Footer from '../components/Footer'


function CoursesLesson() {
    const {user , authenticated} = useContext(UserContext);
    const { lessonSlug } = useParams();
    const { coursesSlug } = useParams();
    const [loading, setLoading] = useState(true);
    const [lesson, setLesson] = useState();
    const [allLesson, setAllLesson] = useState();
    const [progress, setProgress] = useState();
    const [progressCreateNew, setProgressCreateNew] = useState(false);


    
//      useEffect(() => {
    
//   }, []);

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken')
    Axios.post('https://apicourse.jknowledgetutor.com/authsignin', {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + accessToken
      }
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          // console.log('Valid Token');
          try {
            if (coursesSlug) {
          const accessToken = localStorage.getItem("accessToken");

                Axios.post(`https://apicourse.jknowledgetutor.com/getLessonContent/${coursesSlug}/${lessonSlug}`,{
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                  }
                }

                ).then((res) => {
                    setLesson(res.data)
                })
                Axios.get(`https://apicourse.jknowledgetutor.com/getLesson/${coursesSlug}`).then((res) => {
                    setAllLesson(res.data)
                })
                setLoading(false)
            }
        } catch (error) {
            console.log(error);
        }
        } else {
        //   alert('Invalid Token!, Please login.')
          localStorage.removeItem('accessToken')
          window.location = '/login'
        }
      })
        
    }, [])

    useEffect(() => {
      try {
        if (user) {
          const accessToken = localStorage.getItem("accessToken");
          Axios.post(`https://apicourse.jknowledgetutor.com/getLessonProgress/${user.user_id}`,{
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            }
          }).then(
            (res) => {
              setProgress(res.data);
            }
          );
        }
      } catch (error) {
        console.log(error);
      }
    }, [user]);

    return (
        <>
            {/* <LoadingScreen loading={loading}
                bgColor="rgba(255,255,255,0.8)"
                spinnerColor="#478e6b"
                textColor="#676767"
                logoSrc="http://localhost:3000/static/media/Jknowledge-Logo.18250765f4f4ae91679a.webp"
                text="" /> */}
            {authenticated ? <Appbar user={user}/> : <Appbar/>}
            {authenticated ? <CourseLessonContent coursesSlug={coursesSlug} lesson={lesson} allLesson={allLesson} user={user} progress={progress} setProgress={setProgress} /> : <CourseLessonContent coursesSlug={coursesSlug} lesson={lesson} allLesson={allLesson}/>}
            <Footer />
        </>
    )
}

export default CoursesLesson
import React, { useState, useEffect } from "react";
import ActionCard from "./ActionCard";
import Stack from "@mui/material/Stack";
import Axios from "axios";
import Flickity from "react-flickity-component";
import "./Flickity.css";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";

import {
  Button,
  CardActionArea,
  CardActions,
  Box,
  Autocomplete,
  TextField,
  Chip, 
} from "@mui/material";

function CoursesList({user}) {
  const [allcourses, setAllcourses] = useState([]);
  const [courseTagData, setCourseTagData] = useState([]);
  const [initial, setInitial] = useState(false);
  const [filteredID2, setFilteredID2] = useState();
  const [filteredID11, setFilteredID11] = useState();
  const [filteredPrimary, setFilteredPrimary] = useState();
  const [filteredLatePrimary, setFilteredLatePrimary] = useState();
  const [filteredElement, setFilteredElement] = useState();
  const [filteredHighSchool, setFilteredHighSchool] = useState();
  const [filteredID20, setFilteredID20] = useState();
  const [inputValue, setInputValue] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [searchCourse, setSearchCourse] = useState([]);
  const [chipData, setChipData] = useState([]);
  const [filterExam, setFilterExam] = useState([]);
  const [userFavCourses, setUserFavCourses] = useState();


  useEffect(() => {
    try {
      if (user) {
        // console.log('User',user);
        Axios.get(`https://apicourse.jknowledgetutor.com/getUserCourses/${user.user_id}`).then(
          (res) => {
            setUserFavCourses(JSON.parse(res.data[0].fav_courses));
          }
        );
      }
    } catch (error) {
    }
  }, [user]);

  const flickityOptions = {
    initialIndex: 0,
    autoPlay: 0,
    selectedAttraction: 0.01,
    friction: 0.15,
    contain: true,
  };

  const handleClickChip = (data) => {
    const check_index = chipData.findIndex(
      (item) => item.tag_id === data.tag_id
    );
    if (check_index !== -1) {
      // Found
    } else {
      // Not Found
      setChipData((chipData) => [
        ...chipData,
        {
          tag_id: data.tag_id,
          tag_name: data.tag_name,
          tag_color: data.tag_color,
        },
      ]);
      const filterItem = () => {
        let filterTag = [];
        allcourses.map((courses, key) =>
          courses.courses_tag.split(",").map((tagId, key) => {
            if (tagId == data.tag_id) {
              filterTag.push(courses);
            }
          })
        );
        return filterTag;
      };
      setFilterExam((filterExam) => [...filterExam, ...filterItem()]);
    }
  };

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.tag_id !== chipToDelete.tag_id)
    );
    const filterItem = () => {
      let filterTag = [];
      filterExam.map((courses, key) => {
        const courseTag = courses.courses_tag.split(",").map((tagId, key) => {
          let boolean = true;
          if (tagId == chipToDelete.tag_id) {
            //Tag Id to Find
            boolean = false;
          }
          return boolean;
        });
        if (courseTag) {
          if (!courseTag.includes(false)) {
            filterTag.push(courses);
          }
        }
      });
      return filterTag;
    };
    setFilterExam(filterItem());
  };
  function getUniqueArrayBy(filterExam, key) {
    return [...new Map(filterExam.map((item) => [item[key], item])).values()];
  }

  useEffect(() => {
    setChipData([]);
    setFilterExam([]);
    if (searchValue != "") {
      const filteredData = allcourses.filter((courses) => {
        if (courses.courses_name.includes(searchValue)) {
          return courses;
        }
      });
      setSearchCourse(filteredData);
    } else {
      setSearchCourse("");
    }
  }, [searchValue]);

  useEffect(() => {
    try {
      Axios.get("https://apicourse.jknowledgetutor.com/courses").then((res) => {
        setAllcourses(res.data);
        setInitial(true);

        // console.log(res.data);
      });
      Axios.get(`https://apicourse.jknowledgetutor.com/getCourseTagHighlight`).then((res) => {
        setCourseTagData(res.data);
      });
    } catch (error) {
      console.log(error);
    }
    if (initial) {
      //P.2
      // let filteredID2 = [];
      // allcourses.filter((courses) => {
      //   courses.courses_tag.split(",").map((tagId, key) => {
      //     if (tagId == 2) {
      //       filteredID2.push(courses);
      //     }
      //   });
      //   setFilteredID2(filteredID2);
      //   return filteredID2;
      // });

      //Primary
      let filteredPrimary = [];
      allcourses.filter((courses) => {
        courses.courses_tag.split(",").map((tagId, key) => {
          if (tagId == 1 || tagId == 2 || tagId == 3) {
            //Tag Id to Find
            filteredPrimary.push(courses);
          }
        });
        setFilteredPrimary(filteredPrimary);
        return filteredPrimary;
      });

      //Late Primary
      let filteredLatePrimary = [];
      allcourses.filter((courses) => {
        courses.courses_tag.split(",").map((tagId, key) => {
          if (tagId == 4 || tagId == 5 || tagId == 6) {
            //Tag Id to Find
            filteredLatePrimary.push(courses);
          }
        });
        setFilteredLatePrimary(filteredLatePrimary);
        return filteredLatePrimary;
      });

      //Element
      let filteredElement = [];
      allcourses.filter((courses) => {
        courses.courses_tag.split(",").map((tagId, key) => {
          if (tagId == 7 || tagId == 8 || tagId == 9) {
            //Tag Id to Find
            filteredElement.push(courses);
          }
        });
        setFilteredElement(filteredElement);
        return filteredElement;
      });

      //HighSchool
      let filteredHighSchool = [];
      allcourses.filter((courses) => {
        courses.courses_tag.split(",").map((tagId, key) => {
          if (tagId == 10 || tagId == 11 || tagId == 12) {
            //Tag Id to Find
            filteredHighSchool.push(courses);
          }
        });
        setFilteredHighSchool(filteredHighSchool);
        return filteredHighSchool;
      });

      //M.5
      // let filteredID11 = [];
      // allcourses.filter((courses) => {
      //   courses.courses_tag.split(",").map((tagId, key) => {
      //     if (tagId == 11) {
      //       //Tag Id to Find
      //       filteredID11.push(courses);
      //     }
      //   });
      //   setFilteredID11(filteredID11);
      //   return filteredID11;
      // });

      //Chemistry
      // let filteredID20 = [];
      // allcourses.filter((courses) => {
      //   courses.courses_tag.split(",").map((tagId, key) => {
      //     if (tagId == 20) {
      //       //Tag Id to Find
      //       filteredID20.push(courses);
      //     }
      //   });
      //   setFilteredID20(filteredID20);
      //   return filteredID20;
      // });
    }
  }, [initial]);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Box sx={{ m: 3, width: { xs: "800px", md: "1100px" } }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography flex={1} sx={{ fontSize: "1.2rem" }}>
              คอร์สเรียน
            </Typography>

            <Autocomplete
              freeSolo
              flex={0}
              // id="free-solo-2-demo"
              // disableClearable
              options={courseTagData.map((option) => option.tag_name)}
              sx={{ width: { xs: "60%", sm: "50%", md: "35%" } }}
              // onChange={(event, newValue) => {
              //   setInputValue(newValue);
              // }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setSearchValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="ค้นหา..."
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>

          <Box m={2}>
            {courseTagData.map((data) => {
              return (
                <Button
                  sx={{
                    m: { xs: "1px", sm: "2px", md: "3px" },
                    fontSize: { xs: "12px", sm: "14px", md: "16px" },
                    color: "black",
                    background: data.tag_color,
                    borderRadius: 10,
                  }}
                  key={data.tag_id}
                  // onClick={}
                  onClick={() => handleClickChip(data)}
                >
                  {data.tag_name}
                </Button>
              );
            })}
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box m={2}>
            {chipData.map((chip) => {
              return (
                <Chip
                  sx={{
                    m: "3px",
                    fontSize: "16px",
                    height: "40px",
                    borderRadius: 10,
                    background: chip.tag_color,
                  }}
                  key={chip.tag_id}
                  label={chip.tag_name}
                  onDelete={handleDelete(chip)}
                />
              );
            })}
          </Box>
        </Box>
      </Stack>

      {filterExam?.length === 0 && searchCourse?.length === 0 ? (
        <>
          {/* <Stack direction="row" alignItems="center" justifyContent="center">
            <Box sx={{ m: 3, width: { xs: "800px", md: "1100px" } }}>
              <Typography sx={{ fontSize: "1.1rem" }}>
                คอร์สเรียนแนะนำ
              </Typography>
              <Flickity
                className={"carousel"} // default ''
                elementType={"div"} // default 'div'
                options={flickityOptions} // takes flickity options {}
                reloadOnUpdate // default false
                static // default false
              >
                {allcourses.map((val, key) => {
                  return (
                    <Box sx={{ mx: 1 }} key={key}>
                      <ActionCard
                        userId={user? (user.user_id):null}
                        coursesId={val.courses_id}
                        coursesName={val.courses_name}
                        coursesDetail={val.courses_detail}
                        coursesPic={val.courses_pic}
                        coursesSlug={val.courses_slug}
                        coursesTag={val.courses_tag}
                        courseTime={val.courses_video_length}
                        courseArticle={val.courses_num_articles}
                        courseFav={userFavCourses?.findIndex(
                          (course) => course.course_id === val.courses_id
                        ) === -1
                          ? null
                          : true}
                        userFavCourses={userFavCourses}
                        setUserFavCourses={setUserFavCourses}
                      />
                    </Box>
                  );
                })}
              </Flickity>
            </Box>
          </Stack> */}
          {/* P.2 */}
          {/* <Stack direction='row' alignItems='center' justifyContent='center'>
                  <Box sx={{ m: 3, width: { xs: '800px', md: '1300px' }}}>
                      <Typography sx={{ fontSize: '1.6rem' }}>ป.2</Typography>
                      <Stack direction='row' alignItems='center' justifyContent='left' sx={{flexWrap:'wrap'}}>
                          {filteredID2?.map((val, key) => {
                              return (
                                  <Box sx={{ mx: 1 }} key={key} margin={2}>
                                      <ActionCard coursesName={val.courses_name} coursesDetail={val.courses_detail} coursesPic={val.courses_pic} coursesSlug={val.courses_slug} coursesTag={val.courses_tag} courseTime={val.courses_video_length} courseArticle={val.courses_num_articles}/>
                                  </Box>
                              )
                          })}
                          </Stack>
                  </Box>
              </Stack> */}

          {/* Primary */}
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Box sx={{ m: 3, width: { xs: "800px", md: "1100px" } }}>
              <Typography sx={{ fontSize: "1.1rem" }}>ประถมต้น</Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="left"
                sx={{ flexWrap: "wrap" }}
              >
                {filteredPrimary?.map((val, key) => {
                  return (
                    <Box sx={{ mx: 1 }} key={key} margin={2}>
                      <ActionCard
                        userId={user? (user.user_id):null}
                        coursesId={val.courses_id}
                        coursesName={val.courses_name}
                        coursesDetail={val.courses_detail}
                        coursesPic={val.courses_pic}
                        coursesSlug={val.courses_slug}
                        coursesTag={val.courses_tag}
                        courseTime={val.courses_video_length}
                        courseArticle={val.courses_num_articles}
                        courseFav={userFavCourses?.findIndex(
                          (course) => course.course_id === val.courses_id
                        ) === -1
                          ? null
                          : true}
                        userFavCourses={userFavCourses}
                        setUserFavCourses={setUserFavCourses}
                      />
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          </Stack>

          {/* Late Primary */}
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Box sx={{ m: 3, width: { xs: "800px", md: "1100px" } }}>
              <Typography sx={{ fontSize: "1.1rem" }}>ประถมปลาย</Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="left"
                sx={{ flexWrap: "wrap" }}
              >
                {filteredLatePrimary?.map((val, key) => {
                  return (
                    <Box sx={{ mx: 1 }} key={key} margin={2}>
                      <ActionCard
                        userId={user? (user.user_id):null}
                        coursesId={val.courses_id}
                        coursesName={val.courses_name}
                        coursesDetail={val.courses_detail}
                        coursesPic={val.courses_pic}
                        coursesSlug={val.courses_slug}
                        coursesTag={val.courses_tag}
                        courseTime={val.courses_video_length}
                        courseArticle={val.courses_num_articles}
                        courseFav={userFavCourses?.findIndex(
                          (course) => course.course_id === val.courses_id
                        ) === -1
                          ? null
                          : true}
                        userFavCourses={userFavCourses}
                        setUserFavCourses={setUserFavCourses}
                      />
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          </Stack>

          {/* Element */}
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Box sx={{ m: 3, width: { xs: "800px", md: "1100px" } }}>
              <Typography sx={{ fontSize: "1.1rem" }}>
                มัธยมศึกษาตอนต้น
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="left"
                sx={{ flexWrap: "wrap" }}
              >
                {filteredElement?.map((val, key) => {
                  return (
                    <Box sx={{ mx: 1 }} key={key} margin={2}>
                      <ActionCard
                        userId={user? (user.user_id):null}
                        coursesId={val.courses_id}
                        coursesName={val.courses_name}
                        coursesDetail={val.courses_detail}
                        coursesPic={val.courses_pic}
                        coursesSlug={val.courses_slug}
                        coursesTag={val.courses_tag}
                        courseTime={val.courses_video_length}
                        courseArticle={val.courses_num_articles}
                        courseFav={userFavCourses?.findIndex(
                          (course) => course.course_id === val.courses_id
                        ) === -1
                          ? null
                          : true}
                        userFavCourses={userFavCourses}
                        setUserFavCourses={setUserFavCourses}
                      />
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          </Stack>

          {/* High School */}
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Box sx={{ m: 3, width: { xs: "800px", md: "1100px" } }}>
              <Typography sx={{ fontSize: "1.1rem" }}>
                มัธยมศึกษาตอนปลาย
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="left"
                sx={{ flexWrap: "wrap" }}
              >
                {filteredHighSchool?.map((val, key) => {
                  return (
                    <Box sx={{ mx: 1 }} key={key} margin={2}>
                      <ActionCard
                        userId={user? (user.user_id):null}
                        coursesId={val.courses_id}
                        coursesName={val.courses_name}
                        coursesDetail={val.courses_detail}
                        coursesPic={val.courses_pic}
                        coursesSlug={val.courses_slug}
                        coursesTag={val.courses_tag}
                        courseTime={val.courses_video_length}
                        courseArticle={val.courses_num_articles}
                        courseFav={userFavCourses?.findIndex(
                          (course) => course.course_id === val.courses_id
                        ) === -1
                          ? null
                          : true}
                        userFavCourses={userFavCourses}
                        setUserFavCourses={setUserFavCourses}
                      />
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          </Stack>

          {/* M.5 */}
          {/* <Stack direction="row" alignItems="center" justifyContent="center">
            <Box sx={{ m: 3, width: { xs: "800px", md: "1100px" } }}>
              <Typography sx={{ fontSize: "1.1rem" }}>ม.5</Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="left"
                sx={{ flexWrap: "wrap" }}
              >
                {filteredID11?.map((val, key) => {
                  return (
                    <Box sx={{ mx: 1 }} key={key} margin={2}>
                      <ActionCard
                        coursesName={val.courses_name}
                        coursesDetail={val.courses_detail}
                        coursesPic={val.courses_pic}
                        coursesSlug={val.courses_slug}
                        coursesTag={val.courses_tag}
                        courseTime={val.courses_video_length}
                        courseArticle={val.courses_num_articles}
                        userFavCourses={userFavCourses}
                      />
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          </Stack> */}

          {/* Chemistry */}
          {/* <Stack direction="row" alignItems="center" justifyContent="center">
            <Box sx={{ m: 3, width: { xs: "800px", md: "1100px" } }}>
              <Typography sx={{ fontSize: "1.1rem" }}>เคมี</Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="left"
                sx={{ flexWrap: "wrap" }}
              >
                {filteredID20?.map((val, key) => {
                  return (
                    <Box sx={{ mx: 1 }} key={key} margin={2}>
                      <ActionCard
                        coursesName={val.courses_name}
                        coursesDetail={val.courses_detail}
                        coursesPic={val.courses_pic}
                        coursesSlug={val.courses_slug}
                        coursesTag={val.courses_tag}
                        courseTime={val.courses_video_length}
                        courseArticle={val.courses_num_articles}
                        userFavCourses={userFavCourses}
                      />
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          </Stack> */}
        </>
      ) : (
        <>
          {filterExam?.length != 0 ? (
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Box sx={{ m: 3, width: { xs: "800px", md: "1100px" } }}>
                {/* <Typography sx={{ fontSize: "1.4rem" }}>เคมี</Typography> */}
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                  sx={{ flexWrap: "wrap" }}
                >
                  {getUniqueArrayBy(filterExam, "courses_id")?.map(
                    (val, key) => {
                      return (
                        <Box sx={{ mx: 1 }} key={key} margin={2}>
                          <ActionCard
                            userId={user? (user.user_id):null}
                            coursesId={val.courses_id}
                            coursesName={val.courses_name}
                            coursesDetail={val.courses_detail}
                            coursesPic={val.courses_pic}
                            coursesSlug={val.courses_slug}
                            coursesTag={val.courses_tag}
                            courseTime={val.courses_video_length}
                            courseArticle={val.courses_num_articles}
                            courseFav={userFavCourses?.findIndex(
                              (course) => course.course_id === val.courses_id
                            ) === -1
                              ? null
                              : true}
                            userFavCourses={userFavCourses}
                            setUserFavCourses={setUserFavCourses}
                          />
                        </Box>
                      );
                    }
                  )}
                </Stack>
              </Box>
            </Stack>
          ) : null}

          {searchCourse?.length != 0 ? (
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Box sx={{ m: 3, width: { xs: "800px", md: "1100px" } }}>
                {/* <Typography sx={{ fontSize: "1.4rem" }}>เคมี</Typography> */}
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                  sx={{ flexWrap: "wrap" }}
                >
                  {searchCourse?.map((val, key) => {
                    return (
                      <Box sx={{ mx: 1 }} key={key} margin={2}>
                        <ActionCard
                          userId={user? (user.user_id):null}
                          coursesId={val.courses_id}
                          coursesName={val.courses_name}
                          coursesDetail={val.courses_detail}
                          coursesPic={val.courses_pic}
                          coursesSlug={val.courses_slug}
                          coursesTag={val.courses_tag}
                          courseTime={val.courses_video_length}
                          courseArticle={val.courses_num_articles}
                          courseFav={userFavCourses?.findIndex(
                            (course) => course.course_id === val.courses_id
                          ) === -1
                            ? null
                            : true}
                          userFavCourses={userFavCourses}
                          setUserFavCourses={setUserFavCourses}
                        />
                      </Box>
                    );
                  })}
                </Stack>
              </Box>
            </Stack>
          ) : null}
        </>
      )}
    </>
  );
}

export default CoursesList;

import React, { useState, useEffect } from "react";
import Axios from "axios";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import ArticleIcon from "@mui/icons-material/Article";
import Tablofcontents from "./Tablofcontents";
import DownloadIcon from "@mui/icons-material/Download";

function CoursesContent({user, coursesSlug}) {
  const [course, setCourse] = useState();
  const [lesson, setLesson] = useState();
  const [progress, setProgress] = useState();
  const [courseTag, setCourseTag] = useState();

  // console.log(user);
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = dateObj.getUTCDate().toString().padStart(2, "0");
    const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getUTCFullYear().toString();
    const hour = dateObj.getUTCHours().toString().padStart(2, "0");
    const minute = dateObj.getUTCMinutes().toString().padStart(2, "0");

    const formattedDate = `${day}-${month}-${year}  ${hour}:${minute}`;
    return formattedDate;
  };
  // console.log(userData);

  const TagManage = (tagIn) => {
    if (courseTag) {
      if (tagIn) {
        const Array = tagIn.split(",");
        const mapArray = Array.map((val, key) => {
          const filteredData = courseTag.filter((tag) => tag.tag_id == val)[0];
          return filteredData;
        });
        const result = mapArray.map(({ tag_name, tag_color }) => ({
          tag_name,
          tag_color,
        }));
        return result;
      }
    }
  };
  useEffect(() => {
    try {
      if (user) {
        const accessToken = localStorage.getItem("accessToken");
        Axios.post(`https://apicourse.jknowledgetutor.com/getLessonProgress/${user.user_id}`,{
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }).then(
          (res) => {
            setProgress(res.data);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [user]);
  

  useEffect(() => {
    try {
      if (coursesSlug) {
        Axios.get(`https://apicourse.jknowledgetutor.com/getACourse/${coursesSlug}`).then(
          (res) => {
            setCourse(res.data[0]);
          }
        );
        Axios.get(`https://apicourse.jknowledgetutor.com/getLesson/${coursesSlug}`).then(
          (res) => {
            setLesson(res.data);
          }
        );
        Axios.get(`https://apicourse.jknowledgetutor.com/getCourseTag`).then((res) => {
          setCourseTag(res.data);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);


  try {
    if (lesson) {
      return (
        <>
          <Box>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Box sx={{ mx: { sx: 5, md: 20 } }}>
                <Stack direction="row">
                  <Box flex={9}>
                    <Box borderRadius={10} p={2}>
                      <Box bgcolor="#0e3746" borderRadius={2} m={1} p={2}>
                        <Typography variant="h4" color="white">
                          {course.courses_name}
                        </Typography>
                        <Typography variant="body2" color="#B2B2B2">
                          {course.courses_detail}
                        </Typography>
                        <Typography variant="body1" color="white">
                          อัพเดทล่าสุด
                        </Typography>
                        <Typography variant="body2" color="#B2B2B2">
                          {formatDate(course.courses_created)}
                        </Typography>
                      </Box>
                      <Box>
                        <center>
                          <iframe
                            width="95%"
                            height="450"
                            src={course.courses_preview}
                            title={course.courses_name}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          />
                        </center>
                      </Box>
                      <Box
                        flex={12}
                        sx={{
                          display: { xs: "block", sm: "block", md: "none" },
                        }}
                      >
                        <Box
                          bgcolor="#323232"
                          borderRadius={2}
                          m={2}
                          p={3}
                          mt={3}
                        >
                          {/* (TagManage(course.courses_tag)) */}
                          {TagManage(course.courses_tag).map((val, key) => {
                            return (
                              <>
                                <Chip
                                  label={val.tag_name}
                                  sx={{
                                    backgroundColor: `${val.tag_color}`,
                                    borderRadius: 3,
                                    margin: 1,
                                  }}
                                />
                              </>
                            );
                          })}
                          <Box>
                            <Typography
                              variant="body2"
                              color="white"
                              sx={{ display: "inline-flex" }}
                            >
                              <Box py={1} sx={{ display: "inline-flex" }}>
                                <AccessTimeFilledIcon />
                              </Box>
                              <Box
                                sx={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                  marginLeft: 2,
                                }}
                              >
                                {course.courses_video_length} ชั่วโมง
                              </Box>
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="body2"
                              color="white"
                              sx={{ display: "inline-flex" }}
                            >
                              <Box py={1} sx={{ display: "inline-flex" }}>
                                <ArticleIcon />
                              </Box>
                              <Box
                                sx={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                  marginLeft: 2,
                                }}
                              >
                                {course.courses_num_articles} บทเรียน
                              </Box>
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="body2"
                              color="white"
                              sx={{ display: "inline-flex" }}
                            >
                              <Box py={1} sx={{ display: "inline-flex" }}>
                                <DownloadIcon />
                              </Box>
                              <Box
                                sx={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                  marginLeft: 2,
                                }}
                              >
                                {course.courses_num_resources} เอกสารดาวน์โหลด
                              </Box>
                            </Typography>
                          </Box>
                          <Box py={1}>
                            <Typography variant="body2" color="white">
                              {course.courses_detail}
                            </Typography>
                          </Box>
                          <Box align="center">
                            <Button
                              variant="contained"
                              color="secondary"
                              sx={{
                                borderRadius: 2,
                                width: { xs: "250px", md: "300px" },
                                height: { xs: "50px", md: "300px" },
                                marginTop: 1,
                                marginBottom: 1,
                              }}
                              href={`/courses/${course.courses_slug}/${course.lesson_slug}`}
                            >
                              <Typography
                                sx={{ fontSize: "1rem", fontWeight: 600 }}
                              >
                                เข้าเรียน
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      </Box>

                      <Box bgcolor="#f7f9fa" borderRadius={1} m={2} p={2}>
                        <Typography variant="h6" color="black">
                          คำอธิบาย
                        </Typography>
                        <Typography variant="body2" color="black">
                          {course.courses_description}
                        </Typography>
                      </Box>

                      <Box borderRadius={1} m={2}>
                        <Tablofcontents
                          lesson={lesson}
                          course={course}
                          coursesSlug={coursesSlug}
                          progress={progress}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    flex={3}
                    sx={{ display: { xs: "none", sm: "none", md: "block" } }}
                  >
                    <Box bgcolor="#323232" borderRadius={2} m={2} p={3} mt={3}>
                      <Box align="center">
                      <Box
                        component="img"
                        src={course.courses_pic}
                        sx={{
                          display: "block",
                          width: 160,
                          height: 160,
                          borderRadius: 3,
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                      <Typography variant="h5" color="white" align="center">
                        {course.courses_name}
                      </Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          borderRadius: 2,
                          width: { xs: "150px", md: "200px" },
                          marginTop: 1,
                          marginBottom: 1,
                        }}
                        href={`/courses/${course.courses_slug}/${course.lesson_slug}`}
                      >
                        <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>
                          เข้าเรียน
                        </Typography>
                      </Button>
                      </Box>
                      {TagManage(course.courses_tag).map((val, key) => {
                        return (
                          <>
                            <Chip
                              label={val.tag_name}
                              sx={{
                                backgroundColor: `${val.tag_color}`,
                                borderRadius: 3,
                                margin: 1,
                              }}
                            />
                          </>
                        );
                      })}
                      <Box>
                        <Typography
                          variant="body2"
                          color="white"
                          sx={{ display: "inline-flex" }}
                        >
                          <Box py={1} sx={{ display: "inline-flex" }}>
                            <AccessTimeFilledIcon />
                          </Box>
                          <Box
                            sx={{
                              display: "inline-flex",
                              alignItems: "center",
                              marginLeft: 2,
                            }}
                          >
                            {course.courses_video_length} ชั่วโมง
                          </Box>
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="body2"
                          color="white"
                          sx={{ display: "inline-flex" }}
                        >
                          <Box py={1} sx={{ display: "inline-flex" }}>
                            <ArticleIcon />
                          </Box>
                          <Box
                            sx={{
                              display: "inline-flex",
                              alignItems: "center",
                              marginLeft: 2,
                            }}
                          >
                            {course.courses_num_articles} บทเรียน
                          </Box>
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="body2"
                          color="white"
                          sx={{ display: "inline-flex" }}
                        >
                          <Box py={1} sx={{ display: "inline-flex" }}>
                            <DownloadIcon />
                          </Box>
                          <Box
                            sx={{
                              display: "inline-flex",
                              alignItems: "center",
                              marginLeft: 2,
                            }}
                          >
                            {course.courses_num_resources} เอกสารดาวน์โหลด
                          </Box>
                        </Typography>
                      </Box>
                      <Box py={1}>
                        <Typography variant="body2" color="white">
                          {course.courses_detail}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </>
      );
    }
  } catch (error) {
    console.log(error);
    window.location = "/courses";
  }
}

export default CoursesContent;

import Avatar from "@mui/material/Avatar";
import { Link } from '@mui/material';
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import logo from "../../asset/Jknowledge-Logo.png";
import Image from "mui-image";
import React, { useContext, useState, useEffect } from "react";

export default function ProfileDrawer({user}) {
    const userPages = [
        {
          pageName: "คอร์สเรียน",
          url: "/courses",
        },
        {
          pageName: "ข้อสอบ",
          url: "",
        },{
          pageName: "บทความ",
          url: "",
        },{
          pageName: "แพ็กเกจราคา",
          url: "/subpack",
        },
      ];
      
      const nonUserPages = [
        {
          pageName: "คอร์สเรียน",
          url: "/courses",
        },
        {
          pageName: "แพ็กเกจราคา",
          url: "/subpack",
        },
      ];
      
      const userOptions = [
        {
          pageName: "โปรไฟล์",
          url: "/profile",
        },
        {
          pageName: "การชำระเงิน",
          url: "/payment",
        },
        {
          pageName: "ออกจากระบบ",
          url: "/logout",
        },
      ];
      
      const nonUserOptions = [
        {
          pageName: "Login",
          url: "/login",
        },
      ];

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
      };
      const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
      };
    
      const handleCloseNavMenu = () => {
        setAnchorElNav(null);
      };
    
      const handleCloseUserMenu = () => {
        setAnchorElUser(null);
      };

      const [anchorElNav, setAnchorElNav] = React.useState(null);
      const [anchorElUser, setAnchorElUser] = React.useState(null);

    return (
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Image src={logo} width="175px" duration={0} />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            {user ? (
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {userPages.map((page, key) => (
                  <Button
                    href={page.url}
                    key={key}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 1, color: "black", display: "block" }}
                  >
                    <Typography sx={{ fontSize: "1rem" }}>
                      {page.pageName}
                    </Typography>
                  </Button>
                ))}
              </Menu>
            ) : (
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {nonUserPages.map((page, key) => (
                  <Button
                    href={page.url}
                    key={key}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 1, color: "white", display: "block" }}
                  >
                    <Typography sx={{ fontSize: "1.2rem" }}>
                      {page.pageName}
                    </Typography>
                  </Button>
                ))}
              </Menu>
            )}
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Image src={logo} width="175px" duration={0} />
          </Typography>

          <Box
            sx={{
              mr: 10,
              justifyContent: "flex-end",
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            {user ? (
              <>
                {userPages.map((page, key) => (
                  <Button
                    href={page.url}
                    key={key}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 1, color: "white", display: "block" }}
                  >
                    <Typography sx={{ fontSize: "1.2rem" }}>
                      {page.pageName}
                    </Typography>
                  </Button>
                ))}
              </>
            ) : (
              <>
                {nonUserPages.map((page, key) => (
                  <Button
                    href={page.url}
                    key={key}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 1, color: "white", display: "block" }}
                  >
                    <Typography sx={{ fontSize: "1.2rem" }}>
                      {page.pageName}
                    </Typography>
                  </Button>
                ))}
              </>
            )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open options">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={user ? user?.fname : null}>
                  {user ? (
                    <Typography
                      sx={{ textTransform: "capitalize", fontSize: "1.4rem" }}
                    >
                      {user ? user?.fname.substring(0, 1) : null}
                    </Typography>
                  ) : null}
                </Avatar>
              </IconButton>
            </Tooltip>

            {user ? (
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {userOptions.map((option, key) => (
                  <MenuItem
                    component={Link}
                    href={option.url}
                    key={key}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography textAlign="center">
                      {option.pageName}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            ) : (
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {nonUserOptions.map((option, key) => (
                  <MenuItem
                    component={Link}
                    href={option.url}
                    key={key}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography textAlign="center">
                      {option.pageName}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            )}
          </Box>
        </Toolbar>

    );
}

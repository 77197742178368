import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../pages/Profile";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  CardActions,
  Typography,
  Button,
  Link,
} from "@mui/material";
import Axios from "axios";
import Flickity from "react-flickity-component";
import Stack from "@mui/material/Stack";
import ActionCard from "../ActionCard";

function ProfileHome({ user }) {
  const [userMeta, setUserMeta] = useState();
  const [recentCourses, setRecentCourses] = useState([]);
  const [initial, setInitial] = useState(false);
  const [progress, setProgress] = useState();
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = dateObj.getUTCDate().toString().padStart(2, "0");
    const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getUTCFullYear().toString();
    const hour = dateObj.getUTCHours().toString().padStart(2, "0");
    const minute = dateObj.getUTCMinutes().toString().padStart(2, "0");

    const formattedDate = `${day}-${month}-${year}  ${hour}:${minute}`;
    return formattedDate;
  };

  const flickityOptions = {
    initialIndex: 0,
    selectedAttraction: 0.01,
    friction: 0.15,
    contain: true,
    pageDots: false,
    autoPlay: true,
    wrapAround: true,
    // initialIndex: 0,
  };
  const flickityOptionsAct = {
    initialIndex: 1,
    selectedAttraction: 0.01,
    contain: true,
    pageDots: false,
    autoPlay: false,
    wrapAround: true,
    // initialIndex: 0,
  };
  const ActList = [
    {
      id: 1,
      img: "images/activity/act-en.webp",
      title: "ค่าย On-Site เตรียมติดวิศวะ Engineering CAMP 2023",
      href: "https://jknowledgetutor.com/engineer-camp-2023",
    },
    {
      id: 2,
      img: "images/activity/act-med.webp",
      title: "ค่าย On-Site เตรียมติดหมอ MED CAMP 2023",
      href: "https://jknowledgetutor.com/med-camp2023/",
    },
    {
      id: 3,
      img: "images/activity/act-jtcm.webp",
      title: "ค่าย On-Site Thailand TCAS Multiverse 2023",
      href: "https://jknowledgetutor.com/jtcm-2023-main/",
    },
  ];

  useEffect(() => {
    try {
      if (user) {
        const accessToken = localStorage.getItem("accessToken");
        Axios.post(`https://apicourse.jknowledgetutor.com/getAUserMeta/${user.user_id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }).then((res) => {
          setUserMeta(res.data);
        });
      }
    } catch (error) {}
  }, [user]);
  useEffect(() => {
    try {
      if (user) {
        const accessToken = localStorage.getItem("accessToken");
        Axios.get("https://apicourse.jknowledgetutor.com/getRecentCourses").then((res) => {
          setRecentCourses(res.data);
        });
        Axios.post(`https://apicourse.jknowledgetutor.com/getLessonProgress/${user.user_id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }).then((res) => {
          setProgress(res.data);
          setInitial(true);
        });
      }
    } catch (error) {}
  }, [user]);
  return (
    <>
      <Box>
        <Typography sx={{ fontSize: "1.5rem", marginLeft: 4 }}>
          อัพเดทล่าสุด
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ flexWrap: "wrap", marginLeft: 3 }}
        >
          <Box sx={{ width: { xs: 300, sm: 350, md: 800 } }}>
            <Flickity
              className={"carousel"} // default ''
              elementType={"div"} // default 'div'
              options={flickityOptions} // takes flickity options {}
              reloadOnUpdate // default false
              static // default false
            >
              {recentCourses?.map((val, key) => {
                return (
                  <Box
                    sx={{ mx: 1 }}
                    key={key}
                    margin={1}
                    bgcolor={"#4ab1a54a"}
                    borderRadius={5}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      paddingX={3}
                    >
                      <Box
                        sx={{
                          mx: 1,
                          display: "grid",
                          justifyContent: "center",
                        }}
                        key={key}
                        margin={2}
                        flex={4}
                      >
                        <ActionCard
                          coursesName={val.courses_name}
                          coursesDetail={val.courses_detail}
                          coursesPic={val.courses_pic}
                          coursesSlug={val.courses_slug}
                          coursesTag={val.courses_tag}
                          courseTime={val.courses_video_length}
                          courseArticle={val.courses_num_articles}
                          courseFav={true}
                        />
                      </Box>
                      <Box
                        sx={{
                          mx: 1,
                          display: { xs: "none", sm: "none", md: "block" },
                        }}
                        key={key}
                        margin={2}
                        flex={12}
                      >
                        <Typography sx={{ fontSize: "1rem", marginLeft: 1 }}>
                          {val.courses_detail}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="#009688"
                          sx={{ fontSize: "1rem", marginLeft: 1 }}
                        >
                          อัพเดทเมื่อ {formatDate(val.courses_created)}
                        </Typography>
                      </Box>
                    </Stack>
                    <Box
                      sx={{
                        mx: 1,
                        display: { xs: "block", sm: "block", md: "none" },
                      }}
                      key={key}
                      margin={2}
                    >
                      <Typography sx={{ fontSize: "1rem", marginLeft: 2 }}>
                        {val.courses_detail}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="#009688"
                        sx={{ fontSize: "1rem", marginLeft: 2 }}
                      >
                        อัพเดทเมื่อ {formatDate(val.courses_created)}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Flickity>
          </Box>
        </Stack>

        {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          paddingX={5}
        >
          <Box
            flex={6}
            borderRadius={5}
            sx={{
              display: { xs: "none", md: "flex" },
              m: 2,
              p: 2,
              bgcolor: "#fff",
            }}
          >
            <Box
              sx={{
                minHeight: "150px",
              }}
            ></Box>
          </Box>
          <Box
            flex={6}
            borderRadius={5}
            sx={{
              display: { xs: "none", md: "flex" },
              m: 2,
              p: 2,
              bgcolor: "#fff",
            }}
          >
            <Box
              sx={{
                minHeight: "150px",
              }}
            ></Box>
          </Box>
        </Stack> */}
      </Box>
      <Typography sx={{ fontSize: "1.5rem", marginLeft: 4 }}>
        กิจกรรมที่น่าสนใจ By Jknowledge
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {/* <Stack direction="row" alignItems="center" justifyContent="center"> */}
        <Box
          sx={{
            maxWidth: { xs: 300, sm: 350, md: 900 },
            display: "inline-flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
            },
          }}
        >
          {/* <Flickity
              className={"carousel"} // default ''
              elementType={"div"} // default 'div'
              options={flickityOptionsAct} // takes flickity options {}
              reloadOnUpdate // default false
              static // default false
            > */}
          {ActList.map((val, key) => {
            return (
              <Box
                key={key}
                paddingX={1}
                paddingTop={1}
                maxWidth={{ xs: 300, sm: 260, md: 360 }}
                display={"inherit"}
                // display="inline-flex"
                // height={{ xs: 280, sm: 260, md: 300 }}
              >
                <Card sx={{ borderRadius: 5, border: 3, borderColor: "white" }}>
                  <CardActionArea
                    sx={{ marginLeft: "auto", marginRight: "auto" }}
                    component={Link}
                    target="_blank"
                    href="https://jknowledgetutor.com/%e0%b8%a3%e0%b9%89%e0%b8%b2%e0%b8%99%e0%b8%84%e0%b9%89%e0%b8%b2/"
                    maxWidth={{ xs: 150, sm: 170, md: 200 }}
                  >
                    <CardMedia
                      component="img"
                      image={val.img}
                      alt={val.name}
                      // width={{ xs: 300, sm: 500, md: 600 }}
                    />
                  </CardActionArea>
                  <CardActions sx={{ justifyContent: "center" }}>
                    <Typography
                      maxWidth={{ xs: 250, sm: 250, md: 300 }}
                      sx={{ fontWeight: 500 }}
                      fontSize={{ xs: "0.8rem", sm: "1rem" }}
                      textAlign={"center"}
                    >
                      {val.title}
                    </Typography>
                  </CardActions>
                  <CardActions sx={{ justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        borderRadius: 5,
                        width: { xs: "110px", md: "180px" },
                      }}
                      href={val.href}
                    >
                      <Typography
                        sx={{ fontWeight: 500 }}
                        fontSize={{ xs: "0.8rem", sm: "1rem" }}
                      >
                        รายละเอียด
                      </Typography>
                    </Button>
                  </CardActions>
                </Card>
              </Box>
            );
          })}
          {/* </Flickity> */}
        </Box>
        {/* </Stack> */}
      </Box>
      <Box>
        <Typography sx={{ fontSize: "1.5rem", marginLeft: 5 }}>
          บทความ
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          paddingX={3}
        >
          <Box
            flex={12}
            borderRadius={5}
            sx={{
              display: { xs: "flex", md: "flex" },
              m: 1,
              p: 1,
              bgcolor: "#fff",
            }}
          >
            <Box
              component="img"
              src={"images/activity/act-en.webp"}
              alt={"Article"}
              sx={{
                justifyContent: "left",
                maxWidth: 150,
                height: "fit-content",
                display: { xs: "none", md: "flex" },
              }}
            />
            <Box sx={{ display: "grid", justifyItems: "center" }}>
              <Box
                component="img"
                src={"images/activity/act-en.webp"}
                alt={"Article"}
                sx={{
                  justifyContent: "center",
                  maxWidth: 200,
                  height: "fit-content",
                  display: { xs: "flex", md: "none" },
                }}
              />
              <Typography
                // width={{ xs: 80, sm: 120, md: 200 }}
                sx={{ fontWeight: 500 }}
                fontSize={{ xs: "0.8rem", sm: "1rem" }}
              >
                J Knowledge Tutor กว่า 20 ปี ที่สถาบันกวดวิชา เจ โนว์เลจ
                ได้สร้างฝันให้น้อง ๆ นักเรียนระดับชั้นมัธยมศึกษาตอนปลายมากมาย
                ร่วมสร้างกิจกรรม พัฒนา ขยายโอกาส ยกระดับการเรียนรู้
                บูรณาการศาสตร์ในทุกแขนง เพื่อตอบโจทย์ต่อการศึกษาในปัจจุบัน
                โดยเฉพาะยุคแห่งการพัฒนาอย่างไม่หยุดนิ่งในศตวรรษที่ 21
                <br></br>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    borderRadius: 5,
                    width: { xs: "110px", md: "180px" },
                    height: { xs: "30px", md: "40px" },
                    marginTop: 1,
                  }}
                  href={""}
                >
                  <Typography
                    sx={{ fontWeight: 500 }}
                    fontSize={{ xs: "0.8rem", sm: "1rem" }}
                  >
                    เพิ่มเติม
                  </Typography>
                </Button>
              </Typography>
            </Box>
          </Box>
          {/* <Box
            flex={6}
            borderRadius={5}
            sx={{
              display: { xs: "none", md: "flex" },
              m: 2,
              p: 2,
              bgcolor: "#fff",
            }}
          >
            <Box
              sx={{
                minHeight: "150px",
              }}
            ></Box>
          </Box> */}
        </Stack>
      </Box>
      {/* <Box>
        <Typography sx={{ fontSize: "1.5rem", marginLeft: 5 }}>
          โปรโมชั่น
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          paddingX={5}
        >
          <Box
            flex={12}
            borderRadius={5}
            sx={{
              display: { xs: "none", md: "flex" },
              m: 2,
              p: 2,
              bgcolor: "#fff",
            }}
          >
            <Box
              sx={{
                minHeight: "150px",
              }}
            ></Box>
          </Box>
        </Stack>
      </Box> */}
    </>
  );
}

export default ProfileHome;
